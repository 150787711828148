import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import AboutUs from "./Pages/About";
import Services from "./Pages/Services";
import AOS from "aos";
import "aos/dist/aos.css";
import ContactUs from "./Pages/ContactUs";
import Blog from "./Pages/Blog";
import Privacy from "./Pages/Privacy";
import Erpsolution from "./Pages/Erpsolution";
import Android from "./Pages/Android";
import Webapp from "./Pages/Webapp";
import Webservices from "./Pages/Webservices";
import Accountingsoftware from "./Pages/Accountingsoftware";
import Digitalmarketing from "./Pages/Digitalmarketing";
import Career from "./Pages/Career";
function App() {

  useEffect(() => {
    AOS.init({
      duration: 200,
      easing: "ease-out",
      once: true, // Whether animation should happen only once
    });
  }, []);

  return (
    <div className="App">
      <Helmet>
        <meta
          name="keywords"
          content="Prisha Software, Software Company, Reactjs, PHP, Services, Web Services ,Web development ,Accounting Software, ERP Solution , Web Hosting, Digital LandScape , AcccountDigital, 24/7 Support"
        />
      </Helmet>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/career" element={<Career />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/services/erp-software-development" element={<Erpsolution />} />
          <Route path="/services/android-development-service" element={<Android />} />
          <Route path="/services/webapplication-development" element={<Webapp />} />
          <Route path="/services/webservices" element={<Webservices />} />
          <Route path="/services/accounting-software" element={<Accountingsoftware />} />
          <Route path="/services/digital-marketing" element={<Digitalmarketing />} />
        </Routes>
      </Router>
      {/* <MYSwiper /> */}
    </div>
  );
}

export default App;
