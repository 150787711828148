import React from 'react';
import Layout from "../Components/Layout";
import '../index.css';
import Webappback from '../Components/Assets/Services/Webapp2.png';
import Webapp3 from '../Components/Assets/Services/webapp3.png';
import {
    faArrowUpRightDots,
    faHandHoldingDollar,
    faChartLine,
    faFile,
    faBuilding,
    faCartShopping,
    faGlobe,
    faShieldHalved,
    faWandMagicSparkles
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreativeCommonsShare, faGooglePlay } from '@fortawesome/free-brands-svg-icons';
import MetaTags from 'react-meta-tags';


export default function Webapp() {
    return (
        <div>
            <div>
                <MetaTags>
                    <title>Web Application Development Services | Prisha Software</title>
                    <meta
                        name="description"
                        content="We offer expert web application development services, creating custom, scalable solutions to enhance your online presence and drive business growth."
                    />
                    <meta
                        name="keywords"
                        content="website application development services, web application development services, web app development services, web development services"
                    />

                    {/* Facebook meta tags */}
                    <meta
                        property="og:title"
                        content="Web Application Development Services | Prisha Software"
                    />
                    <meta
                        property="og:image"
                        content="https://www.prishasoftware.com/static/media/webhosting.19b8fab00f5c8e118723.jpg"
                    />
                    <meta
                        property="og:description"
                        content="We offer expert web application development services, creating custom, scalable solutions to enhance your online presence and drive business growth."
                    />

                    {/* LinkedIn meta tags */}
                    <meta
                        property="og:title"
                        content="Web Application Development Services | Prisha Software"
                    />
                    <meta
                        property="og:image"
                        content="https://www.prishasoftware.com/static/media/webhosting.19b8fab00f5c8e118723.jpg"
                    />
                    <meta
                        property="og:description"
                        content="We offer expert web application development services, creating custom, scalable solutions to enhance your online presence and drive business growth."
                    />

                    {/* Twitter meta tags */}
                    <meta
                        name="twitter:title"
                        content="Web Application Development Services | Prisha Software"
                    />
                    <meta
                        name="twitter:image"
                        content="https://www.prishasoftware.com/static/media/webhosting.19b8fab00f5c8e118723.jpg"
                    />
                    <meta
                        name="twitter:description"
                        content="We offer expert web application development services, creating custom, scalable solutions to enhance your online presence and drive business growth."
                    />

                </MetaTags>
            </div>
            <Layout>
                <main>
                    <div className=" sm:pb-16  flex justify-center items-center text-center ">
                        <div className='Erpback'>
                            <img src={Webappback} alt='ERP Background' className=" w-screen object-cover Backimgerp" />
                            <h2 className="text-5xl md:text-6xl lg:text-7xl font-semibold  highlighted-text" style={{ color: "white" }}>
                                Web Application <span style={{ color: "var(--text-color)" }}>Development Services</span>
                            </h2>
                        </div>
                    </div>

                    {/* ERP Solution Section */}
                    <section className="Service-Erp flex lg:mt-[10%] mx-auto px-4 sm:px-2  sm:justify-between lg:px-8 py-4 max-w-screen-xl w-[100%]">
                        <div className="flex flex-col justify-center items-center md:flex-row  md:justify-between md:items-start lg:w-[50%]  sm:w-[100%]">
                            <div className=" md:mr-6">
                                <h2 className="lg:text-5xl font-semibold mb-4  " style={{ color: "var(--primary-color)" }}>
                                    Web Application  <br /> Development Services <br />
                                </h2>
                                <p className="lg:text-2xl text-gray-400 sm:text-sm  Erp-contain ">
                                    In order to provide seamless user experiences and optimize operations, organizations in today's digital environment require
                                    robust and scalable web apps. Our web application development services offer high-performance, personalized solutions that
                                    are adapted to your business's requirements. Regardless of your size—startup or established—our team develops web apps that
                                    boost productivity, spur expansion, and guarantee security.
                                </p>
                            </div>
                        </div>

                        <div className="lg:w-1/2 sm:w-[50%] sm:h-8 lg:mt-24"
                            data-aos="zoom-in"
                            data-aos-delay="300">
                            <img src={Webapp3} alt='ErpbackImg1' className='lg:ml-10 w-[100%] Erpbackimg1' style={{ borderRadius: '10px' }}></img>
                        </div>
                    </section>


                    {/* Our Web Application Services */}
                    <section className="flex mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16 max-w-screen-xxl w-full flex-col text-center mb-10 bg-[#192854]">
                        <div className='w-full lg:mb-2 sm:mb-0' >
                            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-semibold" style={{ color: 'white' }}>
                                <span style={{ color: "var(--text-color)" }}>Our Web </span> Application Services
                            </h1>
                        </div>

                        <div className="flex flex-wrap justify-center mt-8 text-white">
                            {/* Left Column */}
                            <div className="flex flex-col w-full md:w-1/2 lg:w-1/3 px-2">
                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faGooglePlay} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-6 sm:mb-8 text-start h-auto sm:h-[220px]">
                                        <h2 className="text-xl sm:text-2xl font-semibold">Custom Web Apps Development</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3"> We provide end-to-end services from initial planning to final deployment,
                                            and we create web applications from the ground up. We provide specialized solutions that are made to
                                            match your precise demands, whether they are for internal business tools, e-commerce platforms, or customer portals.
                                        </p>
                                    </div>
                                </div>
                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faChartLine} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-6 sm:mb-8 text-start h-auto sm:h-[220px]">
                                        <h2 className="text-xl sm:text-2xl font-semibold" >Progressive web apps (PWA)</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3"> Our group develops Progressive Web apps, which seamlessly blend mobile and web user interfaces.
                                            Without requiring app store downloads, PWAs provide an app-like experience with offline access, push notifications,
                                            and quicker loading times.
                                        </p>
                                    </div>
                                </div>

                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faFile} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-6 sm:mb-8 text-start h-auto sm:h-[220px]" >
                                        <h2 className="text-xl sm:text-2xl font-semibold">Single-Page Applications (SPA)</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3">React, Angular, and Vue. JS are some of the contemporary frameworks we use to create quick,
                                            responsive SPAs. SPAs improve user experiences by offering seamless, uninterrupted navigation and cutting down on
                                            load times.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* Right Column */}
                            <div className="flex flex-col w-full h-full lg:ml-10 md:w-1/2 lg:w-1/3 px-2">
                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faBuilding} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-6 sm:mb-8 text-start h-auto sm:h-[220px]">
                                        <h2 className="text-xl sm:text-2xl font-semibold" >Enterprise Web Applications</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3"> Large-scale business processes can be effectively managed using our enterprise web applications.
                                            Our web apps are dependable, scalable, and secure, and they can be smoothly integrated with your current systems,
                                            be it an ERP, CRM, or any other corporate solution.
                                        </p>
                                    </div>
                                </div>

                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faCartShopping} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-6 sm:mb-8 text-start h-auto sm:h-[220px]  ">
                                        <h2 className="text-xl sm:text-2xl font-semibold">E-commerce Web Applications</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3">We provide bespoke online storefront solutions that guarantee a flawless buying experience.
                                            Our web apps facilitate the smooth growth of your online business, offering everything from product
                                            catalogs and payment gateways to inventory management and order monitoring.
                                        </p>
                                    </div>
                                </div>
                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faGlobe} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-6 sm:mb-8 text-start h-auto sm:h-[220px] ">
                                        <h2 className="text-xl sm:text-2xl font-semibold" >API Development and Integration</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3"> Our team creates scalable, safe APIs that connect your web application to external services,
                                            allowing for improved system compatibility and usefulness. We guarantee seamless data flow and connectivity,
                                            whether it's through payment channels, social media platforms, or cloud services.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    {/* Benefits of Our ERP Solutions */}
                    <section className="Benefits-container mx-auto px-4 sm:px-2 sm:justify-between justify-center lg:px-8 py-4 mb-[10%] max-w-screen-xl w-[100%]">
                        <div className='title-container w-full lg:ml-10'>
                            <h1 className="Benefits-heading text-5xl md:text-6xl lg:text-7xl font-semibold" style={{ color: "var(--primary-color)" }}>
                                <span style={{ color: "var(--text-color)" }}>Benefits of </span> Our Web Applications
                            </h1>
                        </div>
                        <div>
                            <div className="icons-row flex flex-col justify-center items-center md:flex-row md:justify-between lg:w-[100%] sm:w-[100%]">
                                <div className="icon-wrapper md:mr-6"
                                    data-aos-delay="200"
                                    data-aos="zoom-in">
                                    <FontAwesomeIcon icon={faArrowUpRightDots} className='icon h-14 mt-2' />
                                    <h3 className="subheading text-2xl font-semibold">High Performance and Scalability</h3>
                                </div>
                                <div className="icon-wrapper md:mr-6"
                                    data-aos-delay="200"
                                    data-aos="zoom-in">
                                    <FontAwesomeIcon icon={faCreativeCommonsShare} className='icon h-14 mt-2' />
                                    <h3 className="subheading text-2xl font-semibold">Responsive and mobile-friendly design</h3>
                                </div>
                                <div className="icon-wrapper md:mr-6"
                                    data-aos-delay="200"
                                    data-aos="zoom-in">
                                    <FontAwesomeIcon icon={faShieldHalved} className='icon h-14 mt-2' />
                                    <h3 className="subheading text-2xl font-semibold">Enhanced Security </h3>
                                </div>
                            </div>

                            <div className="icons-row flex w-[100%] sm:w-[50%] sm:h-8 lg:mt-20 justify-center lg:ml-[25%]">
                                <div className="icon-wrapper md:mr-6"
                                    data-aos-delay="200"
                                    data-aos="zoom-in">
                                    <FontAwesomeIcon icon={faWandMagicSparkles} className='icon h-14 mt-2' />
                                    <h3 className="subheading text-2xl font-semibold">User-Centered Design</h3>
                                </div>
                                <div className="icon-wrapper md:mr-6"
                                    data-aos-delay="200"
                                    data-aos="zoom-in">
                                    <FontAwesomeIcon icon={faHandHoldingDollar} className='icon h-14 mt-2' />
                                    <h3 className="subheading text-2xl font-semibold">Cost-Effective Solutions </h3>
                                </div>
                            </div>
                        </div>
                    </section>


                    {/* Looking for a reliable software consulting partner? */}
                    <section className="email lg:mb-[5%]">
                        <div className="mx-auto px-4 sm:px-6 lg:px-8 py-16 max-w-screen-xl">
                            <div className="flex flex-col justify-center  text-center items-center">

                                <h3
                                    className="text-5xl font-bold mb-5"
                                    style={{ color: "#01358d" }}
                                >
                                    Looking {" "}
                                    <span style={{ color: "var(--text-color)" }}>for a reliable software consulting partner?</span>
                                </h3>
                                <p className="text-black text-xl">
                                    Get in touch with us right now to ask any questions you may have, schedule a consultation,
                                    or know more about our web application development services. Let us help you realize your web application idea!
                                </p>
                                <div className="my-12 md:w-8/12 lg:w-6/12 xl:w-4/12 rounded-3xl flex flex-col md:flex-row justify-between cursor-pointer items-center h-auto md:h-8">
                                    <input
                                        type="text"
                                        placeholder="Your Email"
                                        className="w-full md:w-10/12 bg-white rounded-3xl h-12  px-4 cursor-pointer outline-none mb-4 md:mb-0 md:mr-2"
                                    />
                                    <div>
                                        <button
                                            className="rounded-3xl h-12  w-[130px] px-6 text-white"
                                            style={{ backgroundColor: "#f9556d" }}
                                        >
                                            Send Email
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>




                    {/* Why Choose Us for Web Application Development?*/}
                    <section className=" mx-auto px-4 sm:px-2 sm:justify-between justify-center lg:px-8 py-4 mb-[10%] max-w-screen-xl w-[100%]">
                        <div className='title-container w-full lg:ml-14   '>
                            <h3 className="text-5xl md:text-6xl lg:text-5xl font-semibold" style={{ color: "var(--primary-color)" }}>
                                <span style={{ color: "var(--text-color)" }}> Why Choose Us</span> for Web Application Development?
                            </h3>
                        </div>
                        <div className="lg:flex justify-center items-center gap-4">
                            <div className="my-2 mb-6 sm:mt-6 lg:w-[50%]">
                                <div
                                    className="relative mb-6 px-4 lg:bottom-12 flex flex-col pb-12 p-8 rounded-lg bg-pink-100"
                                    data-aos="zoom-in"
                                >
                                    <div>
                                        <p>
                                            Skilled Group Our skilled development and design team has a track record of creating web applications that are effective in a variety of sectors.
                                            Our specialty is developing programs that are secure, scalable, and perform well.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className=" sm:flex flex-col lg:top-20 p-8 relative rounded-lg bg-pink-100"
                                    data-aos="zoom-in"
                                    data-aos-delay="200"
                                >
                                    <div>
                                        <p>
                                            We use agile approaches to guarantee quick turnaround times and flexibility. This enables us to promptly deliver your web application
                                            and quickly adjust to changes. We offer complete web application development services to satisfy all of your demands, starting with initial
                                            consultation and continuing with deployment and maintenance.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="lg:relative  sm:my-0 p-8 rounded-lg bg-pink-100 lg:w-[50%]"
                                data-aos="zoom-in"
                            >
                                <div>
                                    <p>
                                        Throughout the whole development process, we collaborate directly with you, keeping you updated at every
                                        turn and making sure the finished product reflects your vision.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                </main>
            </Layout>
        </div>
    )
}
