import React from 'react';
import Layout from "../Components/Layout";
import '../index.css';
import AndroidbackImage from '../Components/Assets/Services/Android.png';
import AndroidImge1 from '../Components/Assets/Services/Android2.png';
import { faMobileButton, faArrowRightArrowLeft, faDisplay, faHeadset } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAppStore, faUikit } from '@fortawesome/free-brands-svg-icons';
import MetaTags from 'react-meta-tags';


export default function Android() {
    return (
        <div>
            <div>
                <MetaTags>
                    <title>Custom Application Development Services | Prisha Software</title>
                    <meta
                        name="description"
                        content="Unlock your business potential with our application development services. We deliver custom solutions for web and mobile apps tailored to your needs."
                    />
                    <meta
                        name="keywords"
                        content="android app development company, android application development services, application development services, ios app development company, ios app development, ios app development services"
                    />

                    {/* Facebook meta tags */}
                    <meta
                        property="og:title"
                        content="Custom Application Development Services | Prisha Software"
                    />
                    <meta
                        property="og:image"
                        content="https://www.prishasoftware.com/static/media/Android.649b8c32b89ba6335fce.png"
                    />
                    <meta
                        property="og:description"
                        content="Unlock your business potential with our application development services. We deliver custom solutions for web and mobile apps tailored to your needs."
                    />

                    {/* LinkedIn meta tags */}
                    <meta
                        property="og:title"
                        content="Custom Application Development Services | Prisha Software"
                    />
                    <meta
                        property="og:image"
                        content="https://www.prishasoftware.com/static/media/Android.649b8c32b89ba6335fce.png"
                    />
                    <meta
                        property="og:description"
                        content="Unlock your business potential with our application development services. We deliver custom solutions for web and mobile apps tailored to your needs."
                    />

                    {/* Twitter meta tags */}
                    <meta
                        name="twitter:title"
                        content="Custom Application Development Services | Prisha Software"
                    />
                    <meta
                        name="twitter:image"
                        content="https://www.prishasoftware.com/static/media/Android.649b8c32b89ba6335fce.png"
                    />
                    <meta
                        name="twitter:description"
                        content="Unlock your business potential with our application development services. We deliver custom solutions for web and mobile apps tailored to your needs."
                    />

                </MetaTags>
            </div>
            <Layout>
                <main>
                    <div className=" sm:pb-16  flex justify-center items-center text-center ">
                        <div className='Erpback'>
                            <img src={AndroidImge1} alt='ERP Background' className=" w-screen object-cover Backimgerp" />
                            <h2 className="text-5xl md:text-6xl lg:text-7xl font-semibold  highlighted-text" style={{ color: "white" }}>
                                Android & IOS <span style={{ color: "var(--text-color)" }}>App Development
                                </span>
                            </h2>
                        </div>
                    </div>

                    {/*Android & iOS App Development Services */}
                    <section className=" Android-services  flex lg:mt-[15%]  lg:mb-[5%]  mx-auto px-4 sm:px-2  sm:justify-between lg:px-4 py-8 max-w-screen-xl w-[100%]">
                        <div className="flex flex-col justify-center items-center md:flex-row  md:mr-6 sm:w-full mb-6 lg:mb-0 md:justify-between md:items-start  lg:w-1/2 ">
                            <div className=" md:mr-6">
                                <h2 className="lg:text-5xl font-semibold mb-4  " style={{ color: "var(--primary-color)" }}>
                                    Android & iOS  <br />App Development <br />  Services
                                </h2>
                                <p className="lg:text-2xl text-gray-400 sm:text-sm  Erp-contain ">
                                    Having a mobile app is crucial for businesses trying to engage customers and streamline operations in
                                    the modern digital world. Our specialized, high-performance solutions for Android and iOS app development
                                    help you realize your ideas. Our team of professionals can create a native app or a cross-platform solution
                                    that matches your business objectives and goes above and beyond what your users anticipate.
                                </p>
                            </div>
                        </div>

                        <div className="lg:w-1/2 sm:w-[50%] sm:h-8 lg:mt-10"
                            data-aos="zoom-in"
                            data-aos-delay="300">
                            <img src={AndroidbackImage} alt='ErpbackImg1' className='lg:ml-10 w-[100%] Erpbackimg1' style={{ borderRadius: '10px' }}></img>
                        </div>
                    </section>


                    {/* Explore Our Services */}
                    <section className="flex mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16 max-w-screen-xxl w-full flex-col text-center mb-10 bg-[#192854] ">
                        <div className='w-full lg:mb-2 sm:mb-0' >
                            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-semibold" style={{ color: 'white' }}>
                                <span style={{ color: "var(--text-color)" }}>Explore </span> Our Services
                            </h1>
                        </div>

                        <div className="flex flex-wrap justify-center mt-8 text-white">
                            {/* Left Column */}
                            <div className="flex flex-col w-full md:w-1/2 lg:w-1/3 px-2">
                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faMobileButton} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-6 sm:mb-8 text-start h-auto sm:h-[220px]">
                                        <h2 className="text-xl sm:text-2xl font-semibold">Native App Development</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3"> We create native apps for both iOS and Android to make sure your app
                                            utilizes all of the capabilities available on each platform. Native apps are the best option for companies
                                            aiming to develop reliable and superior mobile applications since they offer increased security, improved performance,
                                            and a more seamless user experience.
                                        </p>
                                    </div>
                                </div>
                                <div className='flex  gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faArrowRightArrowLeft} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-6 sm:mb-8 text-start h-auto sm:h-[220px]">
                                        <h2 className="text-xl sm:text-2xl font-semibold" >Cross-Platform App Development</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3">With our cross-platform app development services, you can create applications that function flawlessly
                                            on iOS and Android. We develop apps that save time and resources while giving users on both platforms a native-like experience,
                                            thanks to technologies like Flutter and React Native
                                        </p>
                                    </div>
                                </div>

                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faUikit} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-6 sm:mb-8 text-start h-auto sm:h-[220px]" >
                                        <h2 className="text-xl sm:text-2xl font-semibold">UI/UX Design</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3"> The key component of any successful mobile app is the user experience.
                                            Our design team produces user-friendly, aesthetically pleasing, and intuitive experiences that entice
                                            consumers to interact and return.

                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* Right Column */}
                            <div className="flex flex-col w-full h-full lg:ml-10 md:w-1/2 lg:w-1/3 px-2">
                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faDisplay} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-6 sm:mb-8 text-start h-auto sm:h-[220px]">
                                        <h2 className="text-xl sm:text-2xl font-semibold" >App Testing and QA</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3"> A successful app launch depends on quality. We thoroughly test each piece of software we
                                            develop to make sure it is secure, free of bugs, and works well on all platforms. To ensure that your app offers a
                                            faultless experience, our QA team does both manual and automated tests.
                                        </p>
                                    </div>
                                </div>

                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faHeadset} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-6 sm:mb-8 text-start h-auto sm:h-[220px]">
                                        <h2 className="text-xl sm:text-2xl font-semibold">App Maintenance and Support</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3"> To keep your app functioning properly, we provide continuing maintenance and support in
                                            addition to our development services. We make sure that your app stays current and meets the changing demands of
                                            your users by providing updates, bug fixes, and performance monitoring.
                                        </p>
                                    </div>
                                </div>
                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faAppStore} className='h-6 mt-2' />
                                    <div className="mb-8 text-start  h-[220px] ">
                                        <h2 className="text-xl sm:text-2xl font-semibold" >App Store Submission</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3"> We manage the complete process of submitting your app to the Apple App Store and Google Play Store,
                                            making sure it complies with all requirements and is optimized for downloads and visibility.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>



                    {/* Why Choose Us for Mobile App Development? */}
                    <section className=" mx-auto px-4 sm:px-2 sm:justify-between justify-center lg:px-8 py-4 mb-[5%] max-w-screen-xl w-[100%]">
                        <div className='title-container w-full lg:ml-14'>
                            <h3 className="text-5xl md:text-6xl lg:text-5xl font-semibold" style={{ color: "var(--primary-color)" }}>
                                <span style={{ color: "var(--text-color)" }}> Why Choose Us </span> for Mobile App Development?
                            </h3>
                        </div>

                        <div className="lg:flex justify-center items-center gap-4">
                            {/* Section 1 */}
                            <div className="my-2 mb-6 sm:mt-6 lg:w-[45%]">
                                <div
                                    className="why-section relative mb-6 px-4 lg:bottom-8 flex flex-col pb-12 p-8 rounded-lg bg-pink-100"
                                    data-aos="zoom-in"
                                >
                                    <p>
                                        Professional Group Our group of skilled iOS and Android developers has a history of creating popular mobile
                                        applications for a range of businesses. We make sure your app is developed utilizing industry best practices by
                                        keeping up with the most recent innovations and trends.
                                    </p>
                                </div>
                                <div
                                    className="why-section sm:flex flex-col  p-8 relative rounded-lg bg-pink-100"
                                    data-aos="zoom-in"
                                    data-aos-delay="200"
                                >
                                    <p>
                                        Particular Solutions We reject the idea that one size fits all. Every app we create is tailored to your business's
                                        particular requirements, guaranteeing that it supports your goals and provides a distinctive user experience.
                                    </p>
                                </div>
                            </div>

                            {/* Section 2 */}
                            <div className='flex flex-col h-auto  lg:w-[50%] '>
                                <div className="why-section lg:relative lg:mt-[20%] lg:mb-8 sm:my-4 p-8 rounded-lg bg-pink-100" data-aos="zoom-in">
                                    <p>
                                        Adaptive Development We use an agile development style, which enables us to provide solutions on time and promptly
                                        adjust to changes. This guarantees that your app is well-built and adaptable to your business's needs. User-Centered
                                        Method At the center of our design and development approach is the user experience. Our main goal is to develop applications
                                        that are user-friendly, entertaining, and truly beneficial to your users.
                                    </p>
                                </div>

                                {/* Section 3 */}
                                <div className="why-section lg:relative sm:my-10  lg:mt-4 p-8 rounded-lg bg-pink-100 " data-aos="zoom-in" data-aos-delay="200">
                                    <p>
                                        Complete Services We manage every step of the app development process, from conception to release. We can help you at every stage, whether you need design, development, testing, or maintenance.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>



                    {/* Looking for a reliable software consulting partner? */}
                    <section className="email lg:mb-[5%]">
                        <div className="mx-auto px-4 sm:px-6 lg:px-8 py-16 max-w-screen-xl">
                            <div className="flex flex-col justify-center  text-center items-center">

                                <h3
                                    className="text-5xl font-bold mb-5"
                                    style={{ color: "#01358d" }}
                                >
                                    Looking {" "}
                                    <span style={{ color: "var(--text-color)" }}> for a reliable software consulting partner?</span>
                                </h3>
                                <p className="text-black text-xl">
                                    Are you prepared to bring your app idea to life? To find out more about our iOS and Android app development services
                                    and <br />how we can assist in realizing your vision, get in contact with our team right now.

                                </p>
                                <div className="my-12 md:w-8/12 lg:w-6/12 xl:w-4/12 rounded-3xl flex flex-col md:flex-row justify-between cursor-pointer items-center h-auto md:h-8">
                                    <input
                                        type="text"
                                        placeholder="Your Email"
                                        className="w-full md:w-10/12 bg-white rounded-3xl h-12  px-4 cursor-pointer outline-none mb-4 md:mb-0 md:mr-2"
                                    />
                                    <div>
                                        <button
                                            className="rounded-3xl h-12  w-[130px] px-6 text-white"
                                            style={{ backgroundColor: "#f9556d" }}
                                        >
                                            Send Email
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>



                </main>
            </Layout>
        </div>
    )
}
