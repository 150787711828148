import React from "react";
import Layout from "../Components/Layout";

function Privacy() {
  return (
    <div >
      <Layout>
        <div className="p-20 aligncenter justify-between ">
          <h1 className="text-red-600 my-5 text-center text-5xl font-bold font-serif">Privacy Policy</h1>

          <h2 className="text-blue-800 my-2  mt-10 text-3xl font-semibold">Terms and Conditions</h2>
          <p>
            Please read these terms and conditions prior to using the applications and services offered by Prisha Software. By using the application, you agree to be legally bound by the terms and conditions, privacy policy, and terms of services (including, without limitation, all disclaimers, exclusion of warranties, and limitations of liability contained therein). If you do not agree with these terms, please do not use the applications and services offered by Prisha Software.
          </p>

          <h2 className="text-blue-800 my-2  mt-10 text-3xl font-semibold">A Word of Caution</h2>
          <p>
            Prisha Software acts as an interface to this service and is not responsible for any damage caused by the information shown by this application. This application just acts as an interface to subscribe to their services. Prisha Software is not responsible for your subscription or use of third-party services.
          </p>

          <h2 className="text-blue-800 my-2  mt-10 text-3xl font-semibold">Information Collection</h2>
          <p>
            When you install and use the application, upon accepting these terms, a registration SMS may be sent from the application or you may require registering by sending a registration SMS manually. We collect some information when you use the application, such as phone number, mobile device's hardware information, unique ID, and selected location. This information will be kept private to Prisha Software and will not be disclosed to third parties. We collect this information to send you relevant advertisements that conform to ethical standards.
          </p>

          <h2 className="text-blue-800 my-2  mt-10 text-3xl font-semibold">Communication</h2>
          <p>
            You may receive text messages (SMS) from Prisha Software containing new application information, updates, alerts, and commercials. You agree that messages from Prisha Software, received on your registered number, will not constitute unsolicited commercial communication, irrespective of having signed up for any Do Not Disturb call registry.
          </p>

          <h2 className="text-blue-800 my-2  mt-10 text-3xl font-semibold">Information Security</h2>
          <p>
            We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure, or destruction of data. These measures include internal reviews of our data collection, storage, processing practices, and security measures, including appropriate encryption.
          </p>

          <h2 className="text-blue-800 my-2  mt-10 text-3xl font-semibold">Application Availability</h2>
          <p>
            Prisha Software makes no guarantees for the uninterrupted and continuous availability of the applications and/or services or of any specific feature(s) of the applications and/or services. The applications and/or services are provided "AS IS" and are subject to the disclaimers of warranty and limitations of liability as found in this agreement.
          </p>

          <h2 className="text-blue-800 my-2  mt-10 text-3xl font-semibold">Payment Policy</h2>
          <p>
            Once a payment is made or deposited, it is non-refundable.
          </p>

          <h2 className="text-blue-800 my-2  mt-10 text-3xl font-semibold">Customer Service</h2>
          <p>
            Prisha Software does not take responsibility for providing any customer service related to the application. We are only a mediator.
          </p>

          <h2 className="text-blue-800 my-2  mt-10 text-3xl font-semibold">Usage Policy</h2>
          <p>
            This application is intended for business purposes only. If any other business is added, the user may be blocked, and payment will not be refundable.
          </p>

          <h2 className="text-blue-800 my-2  mt-10 text-3xl font-semibold">Policy Updates</h2>
          <p>
            As we update, improve, and expand the Prisha Software App Service, this policy may change. Please refer back to it periodically. By accessing the site, app, or otherwise using the Prisha Software App Services, you consent to the collection, storage, and use of the personal information you provide for any of the services we offer.
          </p>

          <h2 className="text-blue-800 my-2  mt-10 text-3xl font-semibold">User Information</h2>
          <p>
            We respect the privacy of the users of the Prisha Software App Services (“Users” or “you”) and are committed to protecting it. The information about the User collected by us includes: (a) information supplied by Users and (b) information automatically tracked while using a mobile device having Prisha Software App Services enabled.
          </p>

          <h2 className="text-blue-800 my-2  mt-10 text-3xl font-semibold">Registration Information</h2>
          <p>
            To avail certain services on the Prisha Software App, users are required to provide personal information for the registration process, which may include: (a) name, (b) email address, (c) phone number, (d) business entity information, (e) account number, (f) KYC details, and any other information as required. This information enables us to provide the services and improve the user experience.
          </p>

          <h2 className="text-blue-800 my-2  mt-10 text-3xl font-semibold">Contact List Access</h2>
          <p>
            Upon downloading the app, users are requested to share access to their contact list (as stored on their mobile device) to enable sharing of payment/credit updates with their customers. This contact list will be uploaded to improve and enhance the user experience of the app.
          </p>

          <h2 className="text-blue-800 my-2  mt-10 text-3xl font-semibold">Data Sharing</h2>
          <p>
            We do not share your data with any third party except in accordance with the terms of this policy.
          </p>

          <h2 className="text-blue-800 my-2  mt-10 text-3xl font-semibold">Changes to the Terms</h2>
          <p>
            Prisha Software may make changes to these terms from time to time, with or without notice. Any new terms will be made available to you, and continued use of the application and services will be treated as acceptance of the updated terms.
          </p>

          <h2 className="text-blue-800 my-2  mt-10 text-3xl font-semibold">Copyright</h2>
          <p>
            All copyrights of this application are reserved with Prisha Software.
          </p>

          <h2 className="text-blue-800 my-2  mt-10 text-3xl font-semibold">Contact Us</h2>
          <p>
            If there are any questions regarding this privacy policy, you may contact us using the information below:
            <br />
            Prisha Software <br />
            Email: <a href="mailto:info@prishasoftware.com" className="text-blue-600 underline">info@prishasoftware.com</a>
          </p>
        </div>
      </Layout >
    </div >
  );
}

export default Privacy;
