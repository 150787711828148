import React from 'react';
import Layout from "../Components/Layout";
import '../index.css';
import webapp from '../Components/Assets/Services/webimg.jpg';
import Webhosting from '../Components/Assets/Services/webhosting.jpg';
import {
    faShareFromSquare, faServer, faCloudArrowUp, faList, faCartShopping, faGear, faPercent, faDatabase, faBuildingShield, faClock, faTableColumns
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreativeCommonsShare } from '@fortawesome/free-brands-svg-icons';
import MetaTags from 'react-meta-tags';


export default function Webservices() {
    return (
        <div>
            <div>
                <MetaTags>
                    <title>Professional Web & Hosting Services | Prisha Software</title>
                    <meta
                        name="description"
                        content="We provide comprehensive web services, including design, development, and hosting solutions, to help businesses thrive online."
                    />
                    <meta
                        name="keywords"
                        content="web services, web solutions"
                    />

                    {/* Facebook meta tags */}
                    <meta
                        property="og:title"
                        content="Professional Web & Hosting Services | Prisha Software"
                    />
                    <meta
                        property="og:image"
                        content="https://www.prishasoftware.com/static/media/Serviceimage.abd6cd62ba630c6b080b.png"
                    />
                    <meta
                        property="og:description"
                        content="We provide comprehensive web services, including design, development, and hosting solutions, to help businesses thrive online."
                    />

                    {/* LinkedIn meta tags */}
                    <meta
                        property="og:title"
                        content="Professional Web & Hosting Services | Prisha Software"
                    />
                    <meta
                        property="og:image"
                        content="https://www.prishasoftware.com/static/media/Serviceimage.abd6cd62ba630c6b080b.png"
                    />
                    <meta
                        property="og:description"
                        content="We provide comprehensive web services, including design, development, and hosting solutions, to help businesses thrive online."
                    />

                    {/* Twitter meta tags */}
                    <meta
                        name="twitter:title"
                        content="Professional Web & Hosting Services | Prisha Software"
                    />
                    <meta
                        name="twitter:image"
                        content="https://www.prishasoftware.com/static/media/Serviceimage.abd6cd62ba630c6b080b.png"
                    />
                    <meta
                        name="twitter:description"
                        content="We provide comprehensive web services, including design, development, and hosting solutions, to help businesses thrive online."
                    />
                </MetaTags>
            </div>
            <Layout>
                <main>
                    <div className=" sm:pb-16  flex justify-center items-center text-center ">
                        <div className='Erpback'>
                            <img src={webapp} alt='ERP Background' className=" w-screen object-cover Backimgerp" />
                            <h2 className="text-5xl md:text-6xl lg:text-7xl font-semibold  highlighted-text" style={{ color: "white" }}>
                                Web Service & <span style={{ color: "var(--text-color)" }}>Hosting Solutions</span>
                            </h2>
                        </div>
                    </div>


                    {/*Web Services & Hosting Solutions */}
                    <section className="Service-Erp flex lg:mt-[10%] mx-auto px-4 sm:px-2  sm:justify-between lg:px-8 py-4 max-w-screen-xl w-[100%]">
                        <div className="flex flex-col justify-center items-center md:flex-row  md:justify-between md:items-start lg:w-[50%]  sm:w-[100%]">
                            <div className=" md:mr-6">
                                <h2 className="lg:text-5xl font-semibold mb-4  " style={{ color: "var(--primary-color)" }}>
                                    Web Services  <br /> & Hosting Solutions <br />
                                </h2>
                                <p className="lg:text-2xl text-gray-400 sm:text-sm  Erp-contain ">
                                    Our hosting and web services offer businesses a dependable, secure, and scalable infrastructure to support their online presence.
                                    Our hosting services guarantee optimal performance, security, and availability, providing you with peace of mind while your business
                                    expands—whether you're creating a website, application, or e-commerce platform.
                                </p>
                            </div>
                        </div>

                        <div className="lg:w-1/2 sm:w-[50%] sm:h-8 lg:mt-4 "
                            data-aos="zoom-in"
                            data-aos-delay="300">
                            <img src={Webhosting} alt='ErpbackImg1' className='lg:ml-10 w-[100%] Erpbackimg1' style={{ borderRadius: '10px' }}></img>
                        </div>
                    </section>


                    {/* Our Web Hosting Services */}
                    <section className="flex mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16 max-w-screen-xxl w-full flex-col text-center mb-10 bg-[#192854]">
                        <div className='w-full lg:mb-2 sm:mb-0' >
                            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-semibold" style={{ color: 'white' }}>
                                <span style={{ color: "var(--text-color)" }}>Our Web </span> Hosting Services
                            </h1>
                        </div>

                        <div className="flex flex-wrap justify-center mt-8 text-white">
                            {/* Left Column */}
                            <div className="flex flex-col w-full md:w-1/2 lg:w-1/3 px-2">
                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faShareFromSquare} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-6 sm:mb-8 text-start h-auto sm:h-[220px]">
                                        <h2 className="text-xl sm:text-2xl font-semibold">Shared Hosting</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3">Our shared hosting package is a great option for startups and small enterprises as it
                                            provides an affordable way to host websites with moderate traffic. We offer minimal setup required and quick,
                                            dependable hosting so you can concentrate on expanding your business.
                                        </p>
                                    </div>
                                </div>
                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faServer} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-6 sm:mb-8 text-start h-auto sm:h-[220px]">
                                        <h2 className="text-xl sm:text-2xl font-semibold" >VPS Hosting (Virtual Private Server)</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3"> Compared to shared hosting, our VPS hosting provides greater control and freedom.
                                            For medium-sized organizations that need more power and customization, this is a terrific option because you
                                            receive dedicated resources and complete control over your virtual environment.
                                        </p>
                                    </div>
                                </div>

                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faGear} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-6 sm:mb-8 text-start h-auto sm:h-[220px]" >
                                        <h2 className="text-xl sm:text-2xl font-semibold">Dedicated Server Hosting</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3">We provide dedicated server hosting for companies that demand the highest levels of control,
                                            security, and speed. A real server that is all yours to use will be available for customization to your exact specifications.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* Right Column */}
                            <div className="flex flex-col w-full h-full lg:ml-10 md:w-1/2 lg:w-1/3 px-2">
                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faCloudArrowUp} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-6 sm:mb-8 text-start h-auto sm:h-[220px]">
                                        <h2 className="text-xl sm:text-2xl font-semibold" >Cloud Hosting</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3">  Our cloud hosting solutions are incredibly flexible and scalable.
                                            Cloud hosting allows you to scale resources up or down based on your needs, so you only pay for what you use.
                                            It is ideal for enterprises with varying traffic.
                                        </p>
                                    </div>
                                </div>

                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faList} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-6 sm:mb-8 text-start h-auto sm:h-[220px]">
                                        <h2 className="text-xl sm:text-2xl font-semibold">Managed Hosting</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3">We provide completely managed hosting services, taking care of everything from backups and
                                            security updates to server setup and maintenance. This lets you concentrate on your main business
                                            while we handle the technical aspects.
                                        </p>
                                    </div>
                                </div>
                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faCartShopping} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-6 sm:mb-8 text-start h-auto sm:h-[220px] ">
                                        <h2 className="text-xl sm:text-2xl font-semibold" >E-commerce Hosting</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3"> Our e-commerce hosting is designed specifically for online retailers,
                                            offering the uptime, security, and performance required to manage high transaction volumes.
                                            We provide support for well-known e-commerce sites, including Shopify, WooCommerce, and Magento.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    {/*Key Features of Our Hosting Services */}
                    <section className="Benefits-container mx-auto px-4 sm:px-2 sm:justify-between justify-center lg:px-8 py-4 mb-[10%] max-w-screen-xl w-[100%]">
                        <div className='title-container w-full lg:ml-5 '>
                            <h1 className=" text-3xl  lg:text-7xl font-semibold" style={{ color: "var(--primary-color)" }}>
                                <span style={{ color: "var(--text-color)" }}>Key Features </span> of Our Hosting Services
                            </h1>
                        </div>
                        <div style={{ marginTop: '8%' }}>
                            <div className="icons-row flex flex-col justify-center items-center md:flex-row md:justify-between lg:w-[100%] sm:w-[100%]">
                                <div className="icon-wrapper md:mr-6"
                                    data-aos-delay="200"
                                    data-aos="zoom-in">
                                    <FontAwesomeIcon icon={faPercent} className='icon h-14 mt-2' />
                                    <h3 className="subheading text-2xl font-semibold">99.9% Uptime Guarantee</h3>
                                </div>
                                <div className="icon-wrapper md:mr-6"
                                    data-aos-delay="200"
                                    data-aos="zoom-in">
                                    <FontAwesomeIcon icon={faDatabase} className='icon h-14 mt-2' />
                                    <h3 className="subheading text-2xl font-semibold">Daily Backups</h3>
                                </div>
                                <div className="icon-wrapper md:mr-6"
                                    data-aos-delay="200"
                                    data-aos="zoom-in">
                                    <FontAwesomeIcon icon={faBuildingShield} className='icon h-14 mt-2' />
                                    <h3 className="subheading text-2xl font-semibold">Fast and Secure Hosting </h3>
                                </div>
                            </div>

                            <div className="icons-row flex flex-col justify-center items-center md:flex-row md:justify-between lg:w-[100%] sm:w-[100%]" style={{ marginTop: '10%' }}>
                                <div className="icon-wrapper md:mr-6"
                                    data-aos-delay="200"
                                    data-aos="zoom-in">
                                    <FontAwesomeIcon icon={faClock} className='icon h-14 mt-2' />
                                    <h3 className="subheading text-2xl font-semibold">24/7 Customer Support</h3>
                                </div>
                                <div className="icon-wrapper md:mr-6"
                                    data-aos-delay="200"
                                    data-aos="zoom-in">
                                    <FontAwesomeIcon icon={faCreativeCommonsShare} className='icon h-14 mt-2' />
                                    <h3 className="subheading text-2xl font-semibold">Scalability and Flexibility</h3>
                                </div>
                                <div className="icon-wrapper md:mr-6"
                                    data-aos-delay="200"
                                    data-aos="zoom-in">
                                    <FontAwesomeIcon icon={faTableColumns} className='icon h-14 mt-2' />
                                    <h3 className="subheading text-2xl font-semibold">Easy-to-Use Control Panel</h3>
                                </div>
                            </div>
                        </div>
                    </section>


                    {/* Looking for a reliable software consulting partner? */}
                    <section className="email lg:mb-[5%]">
                        <div className="mx-auto px-4 sm:px-6 lg:px-8 py-16 max-w-screen-xl">
                            <div className="flex flex-col justify-center  text-center items-center">

                                <h3
                                    className="text-5xl font-bold mb-5"
                                    style={{ color: "#01358d" }}
                                >
                                    Looking {" "}
                                    <span style={{ color: "var(--text-color)" }}>for a reliable software consulting partner?</span>
                                </h3>
                                <p className="text-black text-xl">
                                    Ready to get started with our web services and hosting solutions? Contact us today to discuss your
                                    hosting needs and let us provide you with a fast, secure, and scalable hosting solution to support your business.
                                </p>
                                <div className="my-12 md:w-8/12 lg:w-6/12 xl:w-4/12 rounded-3xl flex flex-col md:flex-row justify-between cursor-pointer items-center h-auto md:h-8">
                                    <input
                                        type="text"
                                        placeholder="Your Email"
                                        className="w-full md:w-10/12 bg-white rounded-3xl h-12  px-4 cursor-pointer outline-none mb-4 md:mb-0 md:mr-2"
                                    />
                                    <div>
                                        <button
                                            className="rounded-3xl h-12  w-[130px] px-6 text-white"
                                            style={{ backgroundColor: "#f9556d" }}
                                        >
                                            Send Email
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>




                    {/*Why Choose Us for Web Hosting?*/}
                    <section className=" mx-auto px-4 sm:px-2 sm:justify-between justify-center lg:px-8 py-4 mb-[10%] max-w-screen-xl w-[100%]">
                        <div className='title-container w-full lg:ml-[20%]  '>
                            <h3 className="text-5xl md:text-6xl lg:text-5xl font-semibold" style={{ color: "var(--primary-color)" }}>
                                <span style={{ color: "var(--text-color)" }}> Why Choose Us</span>  for Web Hosting?
                            </h3>
                        </div>
                        <div className="lg:flex justify-center items-center gap-4">
                            <div className="my-2 mb-6 sm:mt-6 lg:w-[50%]">
                                <div
                                    className="relative mb-6 px-4 lg:bottom-12 flex flex-col pb-12 p-8 rounded-lg bg-pink-100"
                                    data-aos="zoom-in"
                                >
                                    <div>
                                        <p>
                                            We provide customizable hosting packages made to fit the particular requirements of companies in a variety of sectors.
                                            We offer hosting solutions that meet your needs, whether you're starting a blog or overseeing a sophisticated enterprise program.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className=" sm:flex flex-col lg:top-20 p-8 relative rounded-lg bg-pink-100"
                                    data-aos="zoom-in"
                                    data-aos-delay="200"
                                >
                                    <div>
                                        <p>
                                            Our cutting-edge data centers and strong hosting infrastructure ensure that your website or application will operate at
                                            the highest level. Experience optimal speed, minimal latency, and quick load times for all of your users. We are serious
                                            about security. To safeguard your data, our hosting environments are reinforced with the newest security features, such as
                                            firewalls, virus scanners, DDoS protection, and routine software updates.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="lg:relative  sm:my-4 p-8 rounded-lg bg-pink-100 lg:w-[50%]"
                                data-aos="zoom-in"
                            >
                                <div>
                                    <p>
                                        Our hosting solutions are made to be both excellent and reasonably priced. We ensure that companies of all
                                        sizes may obtain dependable hosting without breaking the bank by providing cheap prices for all hosting plans.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                </main>
            </Layout>
        </div>
    )
}
