import React from 'react';
import Layout from "../Components/Layout";
import '../index.css';
import ErpbackImage from '../Components/Assets/Services/Erpback2.png';
import ErpbackImge1 from '../Components/Assets/Services/Erpback.png';
import { faSackDollar, faLink, faGears, faUser, faPeopleArrows, faChartSimple, faArrowUpRightDots, faArrowUpWideShort, faHandHoldingDollar, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreativeCommonsShare } from '@fortawesome/free-brands-svg-icons';
import MetaTags from 'react-meta-tags';


export default function Erpsolution() {



    return (
        <div>
            <div>
                <MetaTags>
                    <title>ERP Solutions for Business Efficiency | Prisha Software</title>
                    <meta
                        name="description"
                        content="Optimize your business operations with our tailored ERP solutions. We provide scalable & efficient ERP systems to streamline processes & enhance productivity.  "
                    />
                    <meta
                        name="keywords"
                        content="erp solutions"
                    />

                    {/* Facebook meta tags */}
                    <meta
                        property="og:title"
                        content="ERP Solutions for Business Efficiency | Prisha Software"
                    />
                    <meta
                        property="og:image"
                        content="https://www.prishasoftware.com/static/media/Erpback2.02334fa16e2ed936444a.png"
                    />
                    <meta
                        property="og:description"
                        content="Optimize your business operations with our tailored ERP solutions. We provide scalable & efficient ERP systems to streamline processes & enhance productivity."
                    />

                    {/* LinkedIn meta tags */}
                    <meta
                        property="og:title"
                        content="ERP Solutions for Business Efficiency | Prisha Software"
                    />
                    <meta
                        property="og:image"
                        content="https://www.prishasoftware.com/static/media/Erpback2.02334fa16e2ed936444a.png"
                    />
                    <meta
                        property="og:description"
                        content="Optimize your business operations with our tailored ERP solutions. We provide scalable & efficient ERP systems to streamline processes & enhance productivity."
                    />

                    {/* Twitter meta tags */}
                    <meta
                        name="twitter:title"
                        content="ERP Solutions for Business Efficiency | Prisha Software"
                    />
                    <meta
                        name="twitter:image"
                        content="https://www.prishasoftware.com/static/media/Erpback2.02334fa16e2ed936444a.png"
                    />
                    <meta
                        name="twitter:description"
                        content="Optimize your business operations with our tailored ERP solutions. We provide scalable & efficient ERP systems to streamline processes & enhance productivity."
                    />
                </MetaTags>
            </div>
            <Layout>
                <main>
                    <div className=" sm:pb-16  flex justify-center items-center text-center ">
                        <div className='Erpback'>
                            <img src={ErpbackImage} alt='ERP Background' className=" w-screen object-cover Backimgerp " />
                            <h2 className="text-5xl md:text-6xl lg:text-7xl font-semibold  highlighted-text" style={{ color: "white" }}>
                                Enterprise <span style={{ color: "var(--text-color)" }}>Software Development</span>
                            </h2>
                        </div>
                    </div>

                    {/* ERP Solution Section */}
                    <section className="Service-Erp flex lg:mt-[10%]  mx-auto px-4 sm:px-2  sm:justify-between lg:px-8 py-4 max-w-screen-xl w-[100%]">
                        <div className="flex flex-col justify-center items-center md:flex-row  md:justify-between md:items-start lg:w-[50%]  sm:w-[100%]">
                            <div className=" md:mr-6">
                                <h2 className="lg:text-5xl font-semibold mb-4  " style={{ color: "var(--primary-color)" }}>
                                    ERP Solutions <br /> Personalized to <br /> Meet Your
                                    <span> Business's Need</span>
                                </h2>
                                <p className="lg:text-2xl text-gray-400 sm:text-sm  Erp-contain ">
                                    Having an integrated system to manage your operations is essential in the fast-paced corporate world of today.
                                    Businesses can operate more efficiently, streamline processes, and get real-time insights with our ERP (Enterprise
                                    Resource Planning) systems, which offer a unified platform. Regardless of the size of your business, our ERP solutions
                                    are made to expand with you and facilitate seamless departmental operations.
                                </p>
                            </div>
                        </div>

                        <div className="lg:w-1/2 sm:w-[50%] sm:h-8 lg:mt-24"
                            data-aos="zoom-in"
                            data-aos-delay="300">
                            <img src={ErpbackImge1} alt='ErpbackImg1' className='lg:ml-10 w-[100%] Erpbackimg1' style={{ borderRadius: '10px' }}></img>
                        </div>
                    </section>


                    {/* Key Features */}
                    <section className="flex mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16 max-w-screen-xxl w-full flex-col text-center mb-10 bg-[#192854]">
                        <div className='w-full lg:mb-2 sm:mb-0'>
                            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-semibold" style={{ color: 'white' }}>
                                <span style={{ color: "var(--text-color)" }}>Key Features</span> of Our ERP Solutions
                            </h1>
                        </div>

                        <div className="flex flex-wrap justify-center mt-8 text-white">
                            {/* Left Column */}
                            <div className="flex flex-col w-full md:w-1/2 lg:w-1/3 px-2">
                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faSackDollar} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-6 sm:mb-8 text-start h-auto sm:h-[220px]">
                                        <h2 className="text-xl sm:text-2xl font-semibold">Finance and Accounting Management</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3"> Management of Finance and Accounting Financial operations like payroll, budgeting, accounts payable, and accounts receivable are all automated and streamlined by our ERP system. Get a comprehensive picture of your financial situation in real time to ensure compliance and wise choices.</p>
                                    </div>
                                </div>
                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faLink} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-6 sm:mb-8 text-start h-auto sm:h-[220px]">
                                        <h2 className="text-xl sm:text-2xl font-semibold">Inventory and Supply Chain Management</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3">Supply Chain and Inventory Management Monitor stock levels, control the purchase process, and enhance supply chain efficiency. With the help of our ERP system, you can manage orders, predict demand, and cut down on extra inventory to guarantee on-time deliveries and happy clients.</p>
                                    </div>
                                </div>
                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faGears} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-6 sm:mb-8 text-start h-auto sm:h-[220px]">
                                        <h2 className="text-xl sm:text-2xl font-semibold">Sales and CRM</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3">CRM (Customer Relationship Management) and sales Integrated CRM capabilities let you track sales activity, manage customer data, and forge closer bonds with customers. Our ERP solution helps businesses increase client retention and sales performance, from lead management to customer care.</p>
                                    </div>
                                </div>
                            </div>

                            {/* Right Column */}
                            <div className="flex flex-col w-full md:w-1/2 lg:w-1/3 px-2 lg:ml-10">
                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faUser} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-6 sm:mb-8 text-start h-auto sm:h-[220px]">
                                        <h2 className="text-xl sm:text-2xl font-semibold">Human Resources and Payroll</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3">Payroll and Human Resources Easily oversee your whole staff. Our ERP system gives you the tools to develop talent and guarantee compliance with labor laws with modules for payroll, employee performance tracking, hiring, and time management.</p>
                                    </div>
                                </div>
                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faPeopleArrows} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-6 sm:mb-8 text-start h-auto sm:h-[220px]">
                                        <h2 className="text-xl sm:text-2xl font-semibold">Production Planning and Manufacturing</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3">Manufacturing and Production Scheduling Our ERP solution provides manufacturing companies with work order tracking, resource allocation optimization, and on-time production tools for production planning. You can keep an eye on production operations and make any adjustments with real-time data.</p>
                                    </div>
                                </div>
                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faChartSimple} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-6 sm:mb-8 text-start h-auto sm:h-[220px]">
                                        <h2 className="text-xl sm:text-2xl font-semibold">Business Intelligence and Analytics</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3">Analytics and business intelligence With the business intelligence capabilities integrated into our ERP system, you can track KPIs, create in-depth reports, and examine data patterns. You can react swiftly to shifting business conditions and make better, data-driven decisions with real-time information.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    {/* Benefits of Our ERP Solutions */}
                    <section className="Benefits-container mx-auto px-4 sm:px-2 sm:justify-between justify-center lg:px-8 py-4 mb-[10%] max-w-screen-xl w-[100%]">
                        <div className='title-container w-full lg:ml-10'>
                            <h1 className="Benefits-heading text-5xl sm:text-4xl md:text-5xl lg:text-6xl  lg:ml-[10%] font-semibold" style={{ color: "var(--primary-color)" }}>
                                <span style={{ color: "var(--text-color)" }}>Benefits of </span> Our ERP Solutions
                            </h1>
                        </div>
                        <div>
                            <div className="icons-row flex flex-col justify-center items-center md:flex-row md:justify-between lg:w-[100%] sm:w-[100%]">
                                <div className="icon-wrapper md:mr-6"
                                    data-aos-delay="200"
                                    data-aos="zoom-in">
                                    <FontAwesomeIcon icon={faArrowUpRightDots} className='icon h-14 mt-2' />
                                    <h3 className="subheading text-2xl font-semibold">Improved Efficiency and Productivity</h3>
                                </div>
                                <div className="icon-wrapper md:mr-6"
                                    data-aos-delay="200"
                                    data-aos="zoom-in">
                                    <FontAwesomeIcon icon={faCreativeCommonsShare} className='icon h-14 mt-2' />
                                    <h3 className="subheading text-2xl font-semibold">Real-Time Data for Better Decision-Making</h3>
                                </div>
                                <div className="icon-wrapper md:mr-6"
                                    data-aos-delay="200"
                                    data-aos="zoom-in">
                                    <FontAwesomeIcon icon={faArrowUpWideShort} className='icon h-14 mt-2' />
                                    <h3 className="subheading text-2xl font-semibold">Scalable Solutions for Growing Businesses</h3>
                                </div>
                            </div>

                            <div className="icons-row flex w-[100%] sm:w-[50%] sm:h-8 lg:mt-20 justify-center lg:ml-[25%]">
                                <div className="icon-wrapper md:mr-6"
                                    data-aos-delay="200"
                                    data-aos="zoom-in">
                                    <FontAwesomeIcon icon={faHandHoldingDollar} className='icon h-14 mt-2' />
                                    <h3 className="subheading text-2xl font-semibold">Cost Savings</    h3>
                                </div>
                                <div className="icon-wrapper md:mr-6"
                                    data-aos-delay="200"
                                    data-aos="zoom-in">
                                    <FontAwesomeIcon icon={faPeopleGroup} className='icon h-14 mt-2' />
                                    <h3 className="subheading text-2xl font-semibold">Enhanced Collaboration</h3>
                                </div>
                            </div>
                        </div>
                    </section>


                    {/* Looking for a reliable software consulting partner? */}
                    <section className="email lg:mb-[5%]">
                        <div className="mx-auto px-4 sm:px-6 lg:px-8 py-16 max-w-screen-xl">
                            <div className="flex flex-col justify-center  text-center items-center">

                                <h3
                                    className="text-5xl font-bold mb-5"
                                    style={{ color: "#01358d" }}
                                >
                                    Looking {" "}
                                    <span style={{ color: "var(--text-color)" }}>for a reliable software consulting partner?</span>
                                </h3>
                                <p className="text-black text-xl">
                                    Are you ready to advance your business to the next level? Make an appointment for a demo,
                                    ask any questions, or discover more about our ERP systems by getting in contact with our team.
                                    Our mission is to support the growth of your business!

                                </p>
                                <div className="my-12 md:w-8/12 lg:w-6/12 xl:w-4/12 rounded-3xl flex flex-col md:flex-row justify-between cursor-pointer items-center h-auto md:h-8">
                                    <input
                                        type="text"
                                        placeholder="Your Email"
                                        className="w-full md:w-10/12 bg-white rounded-3xl h-12  px-4 cursor-pointer outline-none mb-4 md:mb-0 md:mr-2"
                                    />
                                    <div>
                                        <button
                                            className="rounded-3xl h-12  w-[130px] px-6 text-white"
                                            style={{ backgroundColor: "#f9556d" }}
                                        >
                                            Send Email
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>




                    {/*  Why Choose Our ERP Solutions?*/}
                    <section className="mx-auto px-4 sm:px-2 sm:justify-between justify-center lg:px-8 py-4 mb-[10%] max-w-screen-xl w-[100%]">
                        <div className='title-container w-full lg:ml-14   '>
                            <h3 className="text-5xl md:text-6xl lg:text-5xl font-semibold" style={{ color: "var(--primary-color)" }}>
                                <span style={{ color: "var(--text-color)" }}> Why Choose</span> Our ERP Solution ?
                            </h3>
                        </div>
                        <div className="lg:flex justify-center items-center gap-4">
                            <div className="my-2 mb-6 sm:mt-6">
                                <div
                                    className="relative mb-6 px-4 lg:bottom-12 flex flex-col pb-12 p-8 rounded-lg bg-pink-100"
                                    data-aos="zoom-in"
                                >
                                    <div>
                                        <p>
                                            Skilled Application Smooth deployment from beginning to end is guaranteed by our team of ERP experts.
                                            For your team to start working efficiently right away, we evaluate your business needs, adjust the ERP system,
                                            and offer comprehensive training.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className=" sm:flex flex-col lg:top-12 p-8 relative rounded-lg bg-pink-100"
                                    data-aos="zoom-in"
                                    data-aos-delay="200"
                                >
                                    <div>
                                        <p>
                                            Flowing Integration Our ERP systems easily interface with your current CRM, accounting, and eCommerce
                                            platforms, allowing for a seamless transfer that doesn't interfere with your business's operations.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="lg:relative  sm:my-4 p-8 rounded-lg bg-pink-100"
                                data-aos="zoom-in"
                            >
                                <div>
                                    <p>
                                        Ongoing Assistance Our ERP solution is designed to expand with your business, and we support you along the way with system updates and support.
                                        In addition to providing updates and extra training as needed, our committed support staff is on hand to troubleshoot any problems.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>


                </main>
            </Layout>
        </div>
    );
}
