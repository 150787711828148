import React from 'react';
import Layout from "../Components/Layout";
import '../index.css';
import AndroidbackImage from '../Components/Assets/Services/accountimg1.png';
import AndroidImge1 from '../Components/Assets/Services/accountimg.jpg';
import { faRobot, faFileLines, faChalkboard, faReceipt, faPaste, faCoins } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MetaTags from 'react-meta-tags';


export default function Accountingsoftware() {

  return (
    <div>
      <div>
        <MetaTags>
          <title>Accounts Software for Businesses | Prisha Software</title>
          <meta
            name="description"
            content="Simplify your financial management with Prisha Software's accounts software. Efficient, scalable solutions for streamlined bookkeeping, invoicing, and reporting."
          />
          <meta
            name="keywords"
            content="accounts software"
          />

          {/* Facebook meta tags */}
          <meta
            property="og:title"
            content="Accounts Software for Businesses | Prisha Software"
          />
          <meta
            property="og:image"
            content="https://www.prishasoftware.com/static/media/accountimg.547b78ef63ce67707c7a.jpg"
          />
          <meta
            property="og:description"
            content="Simplify your financial management with Prisha Software's accounts software. Efficient, scalable solutions for streamlined bookkeeping, invoicing, and reporting."
          />

          {/* LinkedIn meta tags */}
          <meta
            property="og:title"
            content="Accounts Software for Businesses | Prisha Software"
          />
          <meta
            property="og:image"
            content="https://www.prishasoftware.com/static/media/accountimg.547b78ef63ce67707c7a.jpg"
          />
          <meta
            property="og:description"
            content="Simplify your financial management with Prisha Software's accounts software. Efficient, scalable solutions for streamlined bookkeeping, invoicing, and reporting."
          />

          {/* Twitter meta tags */}
          <meta
            name="twitter:title"
            content="Accounts Software for Businesses | Prisha Software"
          />
          <meta
            name="twitter:image"
            content="https://www.prishasoftware.com/static/media/accountimg.547b78ef63ce67707c7a.jpg"
          />
          <meta
            name="twitter:description"
            content="Simplify your financial management with Prisha Software's accounts software. Efficient, scalable solutions for streamlined bookkeeping, invoicing, and reporting."
          />

        </MetaTags>
      </div>
      <Layout>
        <main>
          <div className=" sm:pb-16  flex justify-center items-center text-center ">
            <div className='Erpback'>
              <img src={AndroidImge1} alt='ERP Background' className=" w-screen object-cover Backimgerp" />
              <h2 className="text-5xl md:text-6xl lg:text-7xl font-semibold  highlighted-text" style={{ color: "white" }}>
                Accounting  <span style={{ color: "var(--text-color)" }}>Software Solutions
                </span>
              </h2>
            </div>
          </div>

          {/*Accounting Software Solutions */}
          <section className="Service-Erp flex lg:mt-[10%] mx-auto px-4 sm:px-2  sm:justify-between lg:px-8 py-4 max-w-screen-xl w-[100%]">
            <div className="flex flex-col justify-center items-center md:flex-row  h-full md:justify-between md:items-start lg:w-[50%]  sm:w-[100%]">
              <div className=" md:mr-6">
                <h2 className="lg:text-5xl font-semibold mb-4  " style={{ color: "var(--primary-color)" }}>
                  Accounting Software <br />Solutions  <br />
                </h2>
                <p className="lg:text-2xl text-gray-400 sm:text-sm  Erp-contain ">
                  Streamline your business's finances with our robust and user-friendly accounting software options.
                  Our software, which is suitable for businesses of all sizes, enables you to track spending, manage
                  finances, produce reports, and adhere to industry standards from a single, user-friendly platform.
                </p>
              </div>
            </div>

            <div className="lg:w-1/2 sm:w-[50%] sm:h-8 "
              data-aos="zoom-in"
              data-aos-delay="300">
              <img src={AndroidbackImage} alt='ErpbackImg1' className=' w-[80%]' style={{ borderRadius: '10px' }}></img>
            </div>
          </section>


          {/* Key Features of Our Accounting Software */}
          <section className="flex mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16 max-w-screen-xxl w-full flex-col text-center mb-10 bg-[#192854]  ">
            <div className='w-full lg:mb-2 sm:mb-0' >
              <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-semibold" style={{ color: 'white' }}>
                <span style={{ color: "var(--text-color)" }}>Key Features </span> of Our Accounting Software
              </h1>
            </div>

            <div className="flex flex-wrap justify-center mt-10 text-white">
              {/* Left Column */}
              <div className="flex flex-col w-full  md:w-1/2 lg:w-1/3 px-2">
                <div className='flex gap-3 sm:gap-4 Features '>
                  <FontAwesomeIcon icon={faRobot} className='h-6 ' />
                  <div className="mb-6 sm:mb-8 text-start h-auto sm:h-[220px]">
                    <h2 className="text-xl sm:text-2xl font-semibold">Automated Bookkeeping</h2>
                    <p className="text-sm sm:text-lg mt-2 sm:mt-3">Using automated bookkeeping can save time and cut down on errors. Our program maintains
                      real-time ledger updates, automatically logs financial activities, and classifies spending.
                    </p>
                  </div>
                </div>
                <div className='flex gap-3 sm:gap-4 Features'>
                  <FontAwesomeIcon icon={faFileLines} className='h-6 ' />
                  <div className="mb-6 sm:mb-8 text-start h-auto sm:h-[220px]">
                    <h2 className="text-xl sm:text-2xl font-semibold" >Invoicing and Billing</h2>
                    <p className="text-sm sm:text-lg mt-2 sm:mt-3"> Within the platform, handle billing, keep track of payments, and create expert invoices.
                      Remind clients automatically while maintaining a consistent cash flow.
                    </p>
                  </div>
                </div>

                <div className='flex gap-3 sm:gap-4 Features'>
                  <FontAwesomeIcon icon={faChalkboard} className='h-6 ' />
                  <div className="mb-6 sm:mb-8 text-start h-auto sm:h-[220px]" >
                    <h2 className="text-xl sm:text-2xl font-semibold">Expense Tracking</h2>
                    <p className="text-sm sm:text-lg mt-2 sm:mt-3"> organize and track business expenses, such as office supplies and utility bills, with ease.
                      Keep an eye on your expenses and find areas where you may make savings.
                    </p>
                  </div>
                </div>
              </div>

              {/* Right Column */}
              <div className="flex flex-col w-full h-full lg:ml-10 md:w-1/2 lg:w-1/3 px-2">
                <div className='flex gap-3 sm:gap-4 Features'>
                  <FontAwesomeIcon icon={faReceipt} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                  <div className="mb-6 sm:mb-8 text-start h-auto sm:h-[220px]">
                    <h2 className="text-xl sm:text-2xl font-semibold" >Financial Reporting</h2>
                    <p className="text-sm sm:text-lg mt-2 sm:mt-3">  Produce comprehensive financial reports, such as cash flow, balance sheet, and profit and loss
                      statements. Real-time information from our software is available to assist you in making wise business decisions.
                    </p>
                  </div>
                </div>

                <div className='flex gap-3 sm:gap-4 Features'>
                  <FontAwesomeIcon icon={faPaste} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                  <div className="mb-2 text-start  lg:h-[200px] ">
                    <h2 className="text-xl sm:text-2xl font-semibold">Tax Compliance</h2>
                    <p className="text-sm sm:text-lg mt-2 sm:mt-3">Continue to abide by national and international tax laws. You can create tax reports, compute taxes,
                      and be ready for tax season with the aid of our accounting software.
                    </p>
                  </div>
                </div>
                <div className='flex gap-3 sm:gap-4 Features'>
                  <FontAwesomeIcon icon={faCoins} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                  <div className="mb-6 sm:mb-8 text-start h-auto sm:h-[220px] ">
                    <h2 className="text-xl sm:text-2xl font-semibold" >Multi-Currency Support</h2>
                    <p className="text-sm sm:text-lg mt-2 sm:mt-3"> Easily handle transactions in several currencies. Our software maintains the accuracy
                      of your accounts while automatically converting foreign currency transactions.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>



          {/*Why Choose Our Accounting Software?*/}

          <section className="mx-auto px-4 sm:px-2 sm:justify-between justify-center lg:px-8 py-4 mb-[5%] max-w-screen-xl w-[100%]">
            <div className='title-container w-full lg:ml-14'>
              <h3 className="text-5xl md:text-6xl lg:text-5xl font-semibold" style={{ color: "var(--primary-color)" }}>
                <span style={{ color: "var(--text-color)" }}> Why Choose Us </span> for Mobile App Development?
              </h3>
            </div>

            <div className="lg:flex justify-center items-center gap-4">
              {/* Left side */}
              <div className="my-2 mb-6 sm:mt-6 lg:w-[45%] flex flex-col justify-between Android-whychooseus" style={{ minHeight: '100%' }}>
                <div
                  className="relative mb-6 px-4 lg:bottom-4 flex flex-col pb-12 p-8 rounded-lg  bg-pink-100 h-full"
                  data-aos="zoom-in"
                >
                  <p>
                    The purpose of our accounting software is to make financial management easier. With its automated features,
                    real-time data analysis, and simple connectivity with other business tools, it's the ideal choice for companies
                    trying to make better decisions and optimize their accounting processes.
                  </p>
                </div>
                <div
                  className=" sm:flex flex-col lg:top-12 p-8 relative rounded-lg bg-pink-100"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                >
                  <div>
                    <p>
                      Our accounting software has strong features that make everything from bookkeeping to tax filing easier, all with the goal of
                      streamlining financial management. You may accurately and easily manage your money with the use of configurable reports,
                      real-time data, and automated processes.
                    </p>
                  </div>
                </div>

              </div>
              {/* Right side */}
              <div
                className="lg:relative lg:w-[48%]  sm:my-4 p-8 rounded-lg bg-pink-100"
                data-aos="zoom-in"
              >
                <div>
                  <p>
                    Our system is scalable to suit the demands of any size organization and guarantees security, compliance, and efficiency.
                    It is also available over the cloud from any location. Our software, which is supported by knowledgeable assistance, enables
                    firms to focus on expansion while saving time and minimizing errors
                  </p>
                </div>
              </div>
            </div>
          </section>


          {/* Looking for a reliable software consulting partner? */}
          <section className="email lg:mb-[5%]">
            <div className="mx-auto px-4 sm:px-6 lg:px-8 py-16 max-w-screen-xl">
              <div className="flex flex-col justify-center  text-center items-center">

                <h3
                  className="text-5xl font-bold mb-5"
                  style={{ color: "#01358d" }}
                >
                  Looking {" "}
                  <span style={{ color: "var(--text-color)" }}> for a reliable software consulting partner?</span>
                </h3>
                <p className="text-black text-xl">
                  Are you prepared to bring your app idea to life? To find out more about our iOS and Android app development services
                  and <br />how we can assist in realizing your vision, get in contact with our team right now.

                </p>
                <div className="my-12 md:w-8/12 lg:w-6/12 xl:w-4/12 rounded-3xl flex flex-col md:flex-row justify-between cursor-pointer items-center h-auto md:h-8">
                  <input
                    type="text"
                    placeholder="Your Email"
                    className="w-full md:w-10/12 bg-white rounded-3xl h-12  px-4 cursor-pointer outline-none mb-4 md:mb-0 md:mr-2"
                  />
                  <div>
                    <button
                      className="rounded-3xl h-12  w-[130px] px-6 text-white"
                      style={{ backgroundColor: "#f9556d" }}
                    >
                      Send Email
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>



        </main>
      </Layout>
    </div>
  )
}
