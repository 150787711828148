import React from "react";
import MetaTags from 'react-meta-tags';
import Layout from "../Components/Layout";
import Like from "../Components/Assets/LIke.png";
import AboutSwiper from "../Components/AboutSwiper";
import { faCheckToSlot, faClock, faLightbulb, faRankingStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIntercom, faServicestack } from "@fortawesome/free-brands-svg-icons";


const images = {
  client1: {
    normal: require("../Components/Assets/Client1.png"),
    hover: require("../Components/Assets/Client1a.png"),
  },
  client2: {
    normal: require("../Components/Assets/Client2.png"),
    hover: require("../Components/Assets/Cient2a.png"),
  },
  client3: {
    normal: require("../Components/Assets/Client3.png"),
    hover: require("../Components/Assets/Client3a.png"),
  },
};
function AboutUs() {

  return (
    <div>
      <MetaTags>
        {/* Standard meta tags */}
        <title>About Prisha Software | Innovative Software Solutions & Services</title>
        <meta
          name="description"
          content="Discover Prisha Software, a leader in software development, ERP solutions, & digital marketing. Learn about our mission, expertise & dedication to driving business growth."
        />
        <meta
          name="keywords"
          content="Prisha Software, Software Company, Reactjs, PHP, About Pages, Software Solution , Digital LandScape , AcccountDigital, 24/7 Support"
        />

        {/* Facebook meta tags */}
        <meta
          property="og:title"
          content="About Prisha Software | Innovative Software Solutions & Services"
        />
        <meta
          property="og:image"
          content="https://www.prishasoftware.com/static/media/Android2.e45d857cad5820537491.png"
        />
        <meta
          property="og:description"
          content="Discover Prisha Software, a leader in software development, ERP solutions, & digital marketing. Learn about our mission, expertise & dedication to driving business growth."
        />

        {/* Twitter meta tags */}
        <meta
          name="twitter:title"
          content="About Prisha Software | Innovative Software Solutions & Services"
        />
        <meta
          name="twitter:image"
          content="https://www.prishasoftware.com/static/media/Android2.e45d857cad5820537491.png"
        />
        <meta
          name="twitter:description"
          content="Discover Prisha Software, a leader in software development, ERP solutions, & digital marketing. Learn about our mission, expertise & dedication to driving business growth."
        />

        {/* LinkedIn meta tags */}
        <meta
          property="og:title"
          content="About Prisha Software | Innovative Software Solutions & Services"
        />
        <meta
          property="og:image"
          content="https://www.prishasoftware.com/static/media/Android2.e45d857cad5820537491.png"
        />
        <meta
          property="og:description"
          content="Discover Prisha Software, a leader in software development, ERP solutions, & digital marketing. Learn about our mission, expertise & dedication to driving business growth."
        />
      </MetaTags>

      <Layout>
        <div>
          <main>
            <div className="mx-auto px-4 sm:px-6 lg:px-8 py-16 max-w-screen-xl">
              <div className="flex flex-col sm:flex-row items-center justify-center sm:space-x-12">
                <div
                  data-aos="zoom-in"
                  data-aos-delay="300"
                  className="flex-shrink-0 mb-6 sm:mb-0 sm:mr-6"
                >
                  <img src={Like} alt="likeus" />
                </div>
                <div>
                  <div className="">
                    {/* <h6 style={{ color: "var(--text-color)" }}>
                      Tailored Software Solutions
                    </h6> */}
                    <h3
                      className="text-3xl sm:text-3xl font-bold mb-6"
                      style={{ color: "var(--primary-color)" }}
                    >
                      Elevate Your Business with
                      <br /> Custom Software Solutions
                    </h3>
                    <p className="text-sm sm:text-xl text-gray-400">
                      Welcome from one of India's leading IT companies, Prisha Software.
                      Since our founding in December 2016, we have been motivated by our
                      affection for technology and our dedication to providing cutting-edge solutions that revolutionize industries.
                    </p>
                    <p className="text-sm sm:text-xl text-gray-400 mt-4">We recognize that the quickly changing IT landscape necessitates adaptability, experience, and a customer-focused strategy.
                      We work hard to stay ahead of the curve and give our clients cutting-edge IT
                      solutions that spur growth and success, supported by a team of highly skilled professionals.
                    </p>

                    {/* <div className="mt-4">
                      <button
                        className="mx-2 my-4 text-white py-2 px-6 sm:px-12 sm:py-4 rounded-full font-semibold"
                        style={{
                          backgroundColor: "var(--text-color)",
                          boxShadow: "0px 6px 13px rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        Read More
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>


            <section className="mx-auto px-4 sm:px-6 lg:px-8 py-5 mb-0 max-w-screen-xl pb-10 ">
              <div className="text-center pb-[5%]">
                <h2 className="text-5xl font-bold" style={{ color: "var(--primary-color)" }} > Why <span style={{ color: "var(--text-color)" }} >Choose Us ? </span></h2>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 gap-8">

                <div
                  className="flex flex-col text-center items-center justify-center"
                  data-aos="zoom-in"
                >
                  <div
                    className="rounded-full w-24 h-24 flex text-2xl font-bold text-white justify-center mb-4 items-center"
                    style={{ backgroundColor: "#FCE7E9" }}
                  >
                    <FontAwesomeIcon icon={faLightbulb} style={{ color: '#01358d' }} className='icon h-14 mt-2' />
                  </div>
                  <div>
                    <h6
                      style={{ color: "#01358d" }}
                      className="text-2xl font-semibold mb-2"
                    >
                      Innovative Design
                    </h6>
                    <p>
                      Award-winning designs that captivate and engage users.
                    </p>
                  </div>
                </div>
                <div
                  className="flex flex-col text-center justify-center items-center"
                  data-aos="zoom-in"
                >
                  <div
                    className="rounded-full w-24 h-24 text-white mb-4 text-2xl font-bold flex justify-center items-center"
                    style={{ backgroundColor: "#FCE7E9" }}
                  >
                    <FontAwesomeIcon icon={faIntercom} style={{ color: '#01358d' }} className='icon h-14 mt-2' />
                  </div>
                  <div>
                    <h6
                      style={{ color: "#01358d" }}
                      className="text-2xl font-semibold mb-4"
                    >
                      Optimal Solutions
                    </h6>
                    <p>
                      Customized solutions tailored to meet your unique business
                      needs.
                    </p>
                  </div>
                </div>
                <div
                  className="flex flex-col text-center justify-center items-center"
                  data-aos="zoom-in"
                >
                  <div
                    className="rounded-full w-24 h-24 flex text-white mb-4 text-2xl font-bold justify-center items-center"
                    style={{ backgroundColor: "#FCE7E9" }}
                  >
                    <FontAwesomeIcon icon={faRankingStar} style={{ color: '#01358d' }} className='icon h-14 mt-2' />
                  </div>
                  <div>
                    <h6
                      style={{ color: "#01358d" }}
                      className="text-2xl font-semibold mb-4"
                    >
                      Top-Quality Development
                    </h6>
                    <p>
                      Highly skilled developers delivering flawless, efficient
                      code.
                    </p>
                  </div>
                </div>
                <div
                  className="flex flex-col text-center justify-center items-center"
                  data-aos="zoom-in"
                >
                  <div
                    className="rounded-full w-24 h-24 flex text-white text-2xl mb-4 font-bold justify-center items-center"
                    style={{ backgroundColor: "#FCE7E9" }}
                  >
                    <FontAwesomeIcon icon={faServicestack} style={{ color: '#01358d' }} className='icon h-14 mt-2' />
                  </div>
                  <div>
                    <h6
                      style={{ color: "#01358d" }}
                      className="text-2xl font-semibold mb-4"
                    >
                      Exceptional Service
                    </h6>
                    <p>
                      Dedicated support team providing prompt and reliable
                      assistance.
                    </p>
                  </div>
                </div>
                <div
                  className="flex flex-col text-center justify-center items-center"
                  data-aos="zoom-in"
                >
                  <div
                    className="rounded-full w-24 h-24 flex text-white mb-4 text-2xl font-bold justify-center items-center"
                    style={{ backgroundColor: "#FCE7E9" }}
                  >
                    <FontAwesomeIcon icon={faCheckToSlot} style={{ color: '#01358d' }} className='icon h-14 mt-2' />
                  </div>
                  <div>
                    <h6
                      style={{ color: "#01358d" }}
                      className="text-2xl font-semibold mb-4"
                    >
                      Affordable Pricing
                    </h6>
                    <p>
                      Competitive pricing plans to fit any budget without
                      compromising quality.
                    </p>
                  </div>
                </div>
                <div
                  className="flex flex-col text-center justify-center items-center"
                  data-aos="zoom-in"
                >
                  <div
                    className="rounded-full w-24 h-24 flex text-white text-2xl mb-4 font-bold justify-center items-center"
                    style={{ backgroundColor: "#FCE7E9" }}
                  >
                    <FontAwesomeIcon icon={faClock} style={{ color: '#01358d' }} className='icon h-14 mt-2' />
                  </div>
                  <div>
                    <h6
                      style={{ color: "#01358d" }}
                      className="text-2xl font-semibold mb-4"
                    >
                      24/7 Support
                    </h6>
                    <p>
                      Round-the-clock assistance ensuring uninterrupted service
                      and peace of mind.
                    </p>
                  </div>
                </div>
              </div>
            </section>



            <section>
              <div className="mx-auto  mt-5 mb-5 px-4 sm:px-6 lg:px-8 py-16 max-w-screen-xl w-full">
                {/* Responsive flexbox for two-column layout */}
                <div className="flex flex-col lg:flex-row justify-between space-y-8 lg:space-y-0 lg:space-x-16 gap-4">

                  {/* Left Side */}
                  <div className="lg:w-1/2">
                    <h3
                      style={{ color: "#01358d", marginTop: '6px' }}
                      className="text-2xl sm:text-3xl lg:text-4xl font-semibold mb-6"
                    >
                      Our Mission
                    </h3>
                    <p className="text-sm sm:text-base lg:text-lg text-justify mb-6">
                      Our mission at Prisha Software is to harness the power of technology
                      to empower enterprises. We truly believe that when applied
                      effectively, technology can change businesses and propel them toward
                      success. Our aim is to be a reliable partner for our clients, helping
                      them to achieve their objectives and navigate the constantly evolving
                      digital landscape.
                    </p>

                    <h3
                      style={{ color: "#01358d" }}
                      className="text-2xl sm:text-3xl lg:text-4xl font-semibold mb-6 mt-6"
                    >
                      Our Strategy
                    </h3>
                    <p className="text-sm sm:text-base lg:text-lg text-justify">
                      It is our humanized approach to technology that differentiates us. We
                      understand that there are real people with real needs behind every
                      line of code and every digital solution. Developing strong
                      relationships with our clients is our first priority, and we take the
                      time to fully comprehend their particular needs and challenges. By
                      putting people at the center of our solutions, we hope to provide
                      customized and user-friendly IT services that significantly improve
                      the quality of life.
                    </p>
                  </div>

                  {/* Right Side */}
                  <div className="lg:w-1/2">
                    <h3
                      style={{ color: "#01358d", marginTop: '6px' }}
                      className="text-2xl sm:text-3xl lg:text-4xl font-semibold mb-6"
                    >
                      Our Team
                    </h3>
                    <p className="text-sm sm:text-base lg:text-lg text-justify mb-6">
                      The group of talented and committed professionals on our team is our
                      greatest asset. The members of our team are united by their love of
                      technology and dedication to leading the way in industry developments.
                      We promote an inclusive and cooperative work environment where
                      creativity is celebrated and new ideas are welcomed.
                    </p>

                    <h3
                      style={{ color: "#01358d" }}
                      className="text-2xl sm:text-3xl lg:text-4xl font-semibold mb-6 mt-6"
                    >
                      Our Commitment
                    </h3>
                    <p className="text-sm sm:text-base lg:text-lg text-justify">
                      Beyond our interactions with clients, we are dedicated to contributing
                      to the community and having a positive influence. We actively seek out
                      opportunities to contribute to social causes and encourage initiatives
                      that lead to positive change in the world. We appreciate you taking
                      the time to get to know us better. We eagerly await the opportunity to
                      collaborate with you and share in your success.
                    </p>
                  </div>
                </div>
              </div>
            </section>


            <section className="email">
              <div className="mx-auto px-4 sm:px-6 lg:px-8 py-16 max-w-screen-xl">
                <div className="flex flex-col justify-center items-center">

                  <h3
                    className="text-6xl font-bold mb-4"
                    style={{ color: "#01358d" }}
                  >
                    Get in{" "}
                    <span style={{ color: "var(--text-color)" }}>touch</span>
                  </h3>
                  <p className="text-white text-2xl">
                    Have questions or inquiries? Reach out to us today. Our team
                    is here to assist you.
                  </p>
                  <div className="my-12 md:w-8/12 lg:w-6/12 xl:w-4/12 rounded-3xl flex flex-col md:flex-row justify-between cursor-pointer items-center h-auto md:h-14">
                    <input
                      type="text"
                      placeholder="Your Email"
                      className="w-full md:w-10/12 bg-white rounded-3xl h-12 px-4 cursor-pointer outline-none mb-4 md:mb-0 md:mr-2"
                    />
                    <div>
                      <button
                        className="rounded-3xl h-12  w-[130px] px-6 text-white"
                        style={{ backgroundColor: "#f9556d" }}
                      >
                        Send Email
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>




            <section>
              <div className="container-fluid px-4 sm:px-6 lg:px-8 py-16">
                <div className="row justify-content-center">
                  <div className="col-12 col-lg-10 col-xl-8">
                    <AboutSwiper images={images} />
                  </div>
                </div>
              </div>
            </section>
            <section className="mx-auto px-4 sm:px-6 lg:px-8 py-16 max-w-screen-xl">
              <div className="flex flex-col md:flex-row">
                <div className="md:w-1/2 md:pr-8">
                  <h6 style={{ color: "var(--text-color)" }}>
                    About Prisha Software
                  </h6>
                  <h3
                    className=" text-3xl sm:text-4xl lg:text-3xl font-bold mb-4"
                    style={{ color: "#01358d" }}
                  >
                    Revolutionizing the Digital Landscape
                  </h3>
                  <p className="text-sm sm:text-xl text-gray-400">
                    Prisha Software is committed to pioneering digital
                    transformation through innovative solutions. Our goal is to
                    redefine the digital landscape and empower businesses to
                    achieve their full potential. Join us in shaping the future
                    of technology.
                  </p>
                </div>

                <div className="md:w-1/2 mt-8 md:mt-0">
                  <div className="flex flex-col space-y-4">
                    <div className="flex justify-between">
                      <span className="text-base font-medium text-blue-700 dark:text-white">
                        Smart work
                      </span>
                      <span
                        className="text-sm font-medium dark:text-white"
                        style={{ color: "var(--text-color)" }}
                      >
                        99%
                      </span>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                      <div
                        className="bg-blue-600 h-2.5 rounded-full"
                        style={{ width: "99%" }}
                      />
                    </div>
                    <div className="flex justify-between">
                      <span
                        className="text-base font-medium dark:text-white"
                        style={{ color: "var(--text-color)" }}
                      >
                        Dedication
                      </span>
                      <span className="text-sm font-medium text-blue-700 dark:text-white">
                        95%
                      </span>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                      <div
                        className="bg-blue-600 h-2.5 rounded-full"
                        style={{ width: "95%" }}
                      />
                    </div>
                    <div className="flex justify-between">
                      <span className="text-base font-medium text-blue-700 dark:text-white">
                        Motivation
                      </span>
                      <span className="text-sm font-medium text-blue-700 dark:text-white">
                        98%
                      </span>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                      <div
                        className="bg-blue-600 h-2.5 rounded-full"
                        style={{ width: "98%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </Layout >
    </div >
  );
}

export default AboutUs;
