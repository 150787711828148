import React from 'react';
import Layout from "../Components/Layout";
import '../index.css';
import Digitalback from '../Components/Assets/Services/digitalback.png';
import digitalmarketing from '../Components/Assets/Services/digitalback1.png';
import {
    faArrowUpRightDots,
    faHandHoldingDollar,
    faPhotoFilm,
    faBullhorn,
    faEnvelope,
    faMoneyBillTransfer,
    faUsersViewfinder,
    faSignal5
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBandcamp, faPaypal, faSearchengin } from '@fortawesome/free-brands-svg-icons';
import MetaTags from 'react-meta-tags';

export default function Digitalmarketing() {


    return (
        <div>
            <div>
                <MetaTags>
                    <title>Expert Digital Marketing Services | Prisha Software</title>
                    <meta
                        name="description"
                        content="Boost your online presence with our digital marketing services. We provide SEO, social media, & PPC strategies to drive targeted traffic & grow your business."
                    />
                    <meta
                        name="keywords"
                        content="digital marketing company, SMM service, social media services, social media marketing company"
                    />


                    {/* Facebook meta tags */}
                    <meta
                        property="og:title"
                        content="Expert Digital Marketing Services | Prisha Software"
                    />
                    <meta
                        property="og:image"
                        content="https://www.prishasoftware.com/static/media/digitalback1.78ec860f9c23cb9f1f06.png"
                    />
                    <meta
                        property="og:description"
                        content="Boost your online presence with our digital marketing services. We provide SEO, social media, & PPC strategies to drive targeted traffic & grow your business."
                    />

                    {/* LinkedIn meta tags */}
                    <meta
                        property="og:title"
                        content="Expert Digital Marketing Services | Prisha Software"
                    />
                    <meta
                        property="og:image"
                        content="https://www.prishasoftware.com/static/media/digitalback1.78ec860f9c23cb9f1f06.png"
                    />
                    <meta
                        property="og:description"
                        content="Boost your online presence with our digital marketing services. We provide SEO, social media, & PPC strategies to drive targeted traffic & grow your business."
                    />

                    {/* Twitter meta tags */}
                    <meta
                        name="twitter:title"
                        content="Expert Digital Marketing Services | Prisha Software"
                    />
                    <meta
                        name="twitter:image"
                        content="https://www.prishasoftware.com/static/media/digitalback1.78ec860f9c23cb9f1f06.png"
                    />
                    <meta
                        name="twitter:description"
                        content="Boost your online presence with our digital marketing services. We provide SEO, social media, & PPC strategies to drive targeted traffic & grow your business."
                    />
                </MetaTags>
            </div>
            <Layout>
                <main>
                    <div className=" sm:pb-16  flex justify-center items-center text-center ">
                        <div className='Erpback'>
                            <img src={Digitalback} alt='ERP Background' className=" w-screen object-cover Backimgerp" />
                            <h2 className="text-5xl md:text-6xl lg:text-7xl font-semibold  highlighted-text" style={{ color: "white" }}>
                                Digital Marketing<span style={{ color: "var(--text-color)" }}> Services</span>
                            </h2>
                        </div>
                    </div>

                    {/* ERP Solution Section */}
                    <section className="Service-Erp flex lg:mt-[10%] mx-auto px-4 sm:px-2  sm:justify-between lg:px-8 py-4 max-w-screen-xl w-[100%]">
                        <div className="flex flex-col justify-center items-center md:flex-row  md:justify-between md:items-start lg:w-[50%]  sm:w-[100%]">
                            <div className=" md:mr-6">
                                <h2 className="lg:text-5xl font-semibold mb-4  " style={{ color: "var(--primary-color)" }}>
                                    Digital Marketing <br /> Services <br />
                                </h2>
                                <p className="lg:text-2xl text-gray-400 sm:text-sm  Erp-contain ">
                                    With the help of our all-inclusive digital marketing services, draw in, hold on to, and convert your target audience.
                                    Our area of expertise is developing customized plans that assist businesses in raising their internet presence, generating
                                    more leads, and increasing revenue. We provide a comprehensive range of services to expand your business online,
                                    from social media marketing to SEO.
                                </p>
                            </div>
                        </div>

                        <div className="lg:w-1/2 sm:w-[50%] sm:h-8 lg:mt-16"
                            data-aos="zoom-in"
                            data-aos-delay="300">
                            <img src={digitalmarketing} alt='ErpbackImg1' className='lg:ml-10 w-[100%] Erpbackimg1' style={{ borderRadius: '10px' }}></img>
                        </div>
                    </section>


                    {/* Our Digital Marketing Services */}
                    <section className="flex mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16 max-w-screen-xxl w-full flex-col text-center mb-10 bg-[#192854]">
                        <div className='w-full lg:mb-2 sm:mb-0' >
                            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-semibold" style={{ color: 'white' }}>
                                <span style={{ color: "var(--text-color)" }}>Our Digital Marketing  </span>Services
                            </h1>
                        </div>

                        <div className="flex flex-wrap justify-center mt-10 text-white">
                            {/* Left Column */}
                            <div className="flex flex-col w-full  md:w-1/2 lg:w-1/3 px-2">
                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faSearchengin} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-8 text-start  h-[220px]">
                                        <h2 className="text-xl sm:text-2xl font-semibold">Search Engine Optimization (SEO)</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3"> With our SEO services, you may raise your website's organic traffic and search engine ranks.
                                            To make sure your website ranks highly on Google and other search engines, we carry out in-depth keyword
                                            research, on-page optimization, technical SEO enhancements, and link-building.
                                        </p>
                                    </div>
                                </div>
                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faPaypal} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-8 text-start  h-[220px]">
                                        <h2 className="text-xl sm:text-2xl font-semibold" >Pay-Per-Click (PPC) Advertising</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3">  Get the most out of your investment with focused PPC advertising. Using Google Ads,
                                            Bing Ads, or social network advertising, we design campaigns that reduce cost-per-click, increase conversions,
                                            and target your ideal demographic.
                                        </p>
                                    </div>
                                </div>

                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faPhotoFilm} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-8 text-start  h-[220px]" >
                                        <h2 className="text-xl sm:text-2xl font-semibold">Social Media Marketing</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3"> Interact with your clientele on social media sites such as Twitter, Facebook, Instagram,
                                            and LinkedIn. To expand your audience and raise brand awareness, we handle your social media presence,
                                            provide interesting content, and launch paid advertising.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* Right Column */}
                            <div className="flex flex-col w-full h-full lg:ml-10 md:w-1/2 lg:w-1/3 px-2">
                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faBullhorn} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-8 text-start  h-[220px]">
                                        <h2 className="text-xl sm:text-2xl font-semibold" >Content marketing</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3">The king of the digital world is content. We provide excellent, pertinent,
                                            and captivating material that appeals to your intended audience. Content marketing tactics that we employ,
                                            ranging from blog posts and articles to infographics and videos, increase traffic and improve consumer interaction.
                                        </p>
                                    </div>
                                </div>

                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faEnvelope} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-8 text-start  lg:h-[220px] ">
                                        <h2 className="text-xl sm:text-2xl font-semibold">Email Marketing</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3">Utilize email marketing initiatives that are successful in nurturing prospects and preserving
                                            customer connections. We create, carry out, and oversee email marketing campaigns that provide your subscribers with
                                            tailored content in an effort to increase interaction and conversion rates.
                                        </p>
                                    </div>
                                </div>
                                <div className='flex gap-3 sm:gap-4 Features'>
                                    <FontAwesomeIcon icon={faMoneyBillTransfer} className='h-5 sm:h-6 mt-1 sm:mt-2' />
                                    <div className="mb-8 text-start  h-[220px] ">
                                        <h2 className="text-xl sm:text-2xl font-semibold" >Conversion Rate Optimization (CRO)</h2>
                                        <p className="text-sm sm:text-lg mt-2 sm:mt-3"> Make sure that those who visit your website become paying customers.
                                            In order to improve landing pages, boost conversions, and streamline the buyer's journey, our CRO services
                                            concentrate on optimizing the user experience on your website.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    {/*Benefits of Our Digital Marketing Services */}
                    <section className="Benefits-container mx-auto px-4 sm:px-2 sm:justify-between justify-center lg:px-8 py-4 mb-[10%] max-w-screen-xl w-[100%]">
                        <div className='title-container w-full lg:ml-10'>
                            <h1 className="text-5xl md:text-6xl lg:text-6xl font-semibold" style={{ color: "var(--primary-color)" }}>
                                <span style={{ color: "var(--text-color)" }}>Benefits of </span> Our Digital Marketing Services
                            </h1>
                        </div>
                        <div style={{ marginTop: '8%' }}>
                            <div className="icons-row flex flex-col justify-center items-center md:flex-row md:justify-between lg:w-[100%] sm:w-[100%]">
                                <div className="icon-wrapper md:mr-6"
                                    data-aos-delay="200"
                                    data-aos="zoom-in">
                                    <FontAwesomeIcon icon={faArrowUpRightDots} className='icon h-14 mt-2' />
                                    <h3 className="subheading text-2xl font-semibold">Increased Online Visibility</h3>
                                </div>
                                <div className="icon-wrapper md:mr-6"
                                    data-aos-delay="200"
                                    data-aos="zoom-in">
                                    <FontAwesomeIcon icon={faUsersViewfinder} className='icon h-14 mt-2' />
                                    <h3 className="subheading text-2xl font-semibold">Targeted Campaigns</h3>
                                </div>
                                <div className="icon-wrapper md:mr-6"
                                    data-aos-delay="200"
                                    data-aos="zoom-in">
                                    <FontAwesomeIcon icon={faSignal5} className='icon h-14 mt-2' />
                                    <h3 className="subheading text-2xl font-semibold">Data-Driven Results </h3>
                                </div>
                            </div>

                            <div className="icons-row flex w-[100%] sm:w-[60%] sm:h-8 lg:mt-20 justify-center lg:ml-[25%]">
                                <div className="icon-wrapper md:mr-6"
                                    data-aos-delay="200"
                                    data-aos="zoom-in">
                                    <FontAwesomeIcon icon={faHandHoldingDollar} className='icon h-14 mt-2' />
                                    <h3 className="subheading text-2xl font-semibold">Cost-Effective Solutions </h3>
                                </div>
                                <div className="icon-wrapper md:mr-6"
                                    data-aos-delay="200"
                                    data-aos="zoom-in">
                                    <FontAwesomeIcon icon={faBandcamp} className='icon h-14 mt-2' />
                                    <h3 className="subheading text-2xl font-semibold">Enhanced Brand Reputation</h3>
                                </div>
                            </div>
                        </div>
                    </section>


                    {/* Looking for a reliable software consulting partner? */}
                    <section className="email lg:mb-[5%]">
                        <div className="mx-auto px-4 sm:px-6 lg:px-8 py-16 max-w-screen-xl">
                            <div className="flex flex-col justify-center  text-center items-center">

                                <h3
                                    className="text-5xl font-bold mb-5"
                                    style={{ color: "#01358d" }}
                                >
                                    Looking {" "}
                                    <span style={{ color: "var(--text-color)" }}>for a reliable software consulting partner?</span>
                                </h3>
                                <p className="text-black text-xl">
                                    Are you ready to hire our digital marketing services to advance your business's growth? Get in touch with us
                                    right now for a free consultation, and let us develop a digital strategy that helps your business achieve tangible, quantifiable outcomes.
                                </p>
                                <div className="my-12 md:w-8/12 lg:w-6/12 xl:w-4/12 rounded-3xl flex flex-col md:flex-row justify-between cursor-pointer items-center h-auto md:h-8">
                                    <input
                                        type="text"
                                        placeholder="Your Email"
                                        className="w-full md:w-10/12 bg-white rounded-3xl h-12  px-4 cursor-pointer outline-none mb-4 md:mb-0 md:mr-2"
                                    />
                                    <div>
                                        <button
                                            className="rounded-3xl h-12  w-[130px] px-6 text-white"
                                            style={{ backgroundColor: "#f9556d" }}
                                        >
                                            Send Email
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>




                    {/* Why Choose Our Digital Marketing Services?*/}
                    <section className=" mx-auto px-4 sm:px-2 mb-[5%] sm:justify-between justify-center lg:px-8 py-4 max-w-screen-xl w-[100%]">
                        <div className='title-container w-full lg:ml-14   '>
                            <h3 className="text-5xl md:text-6xl lg:text-5xl font-semibold" style={{ color: "var(--primary-color)" }}>
                                <span style={{ color: "var(--text-color)" }}> Why Choose Us</span> for Web Application Development?
                            </h3>
                        </div>
                        <div className="lg:flex justify-center items-center gap-4">
                            {/* Left side */}
                            <div className="my-2 mb-6 sm:mt-6 lg:w-[45%] flex flex-col justify-between Android-whychooseus" style={{ minHeight: '100%' }}>
                                <div
                                    className="relative mb-6 px-4 lg:bottom-4 flex flex-col pb-12 p-8 rounded-lg  bg-pink-100 h-full"
                                    data-aos="zoom-in"
                                >
                                    <p>
                                        To succeed in the highly competitive digital world of today, having a web presence alone is insufficient.
                                        To make sure your business achieves its maximum potential, our knowledgeable staff combines data-driven insights
                                        with innovative tactics. Our emphasis is on quantifiable outcomes, assisting you in accomplishing your business
                                        objectives and maintaining a competitive edge.
                                    </p>
                                </div>
                                <div
                                    className=" sm:flex flex-col lg:top-12 p-8 relative rounded-lg bg-pink-100"
                                    data-aos="zoom-in"
                                    data-aos-delay="200"
                                >
                                    <div>
                                        <p>
                                            We design our digital marketing services to drive targeted visitors, enhance conversions, and raise
                                            online visibility for your business. To effectively reach your audience, we employ data-driven tactics
                                            that combine PPC, social media marketing, SEO, and content marketing.
                                        </p>
                                    </div>
                                </div>

                            </div>
                            {/* Right side */}
                            <div
                                className="lg:relative lg:w-[48%]  sm:my-4 p-8 rounded-lg bg-pink-100"
                                data-aos="zoom-in"
                            >
                                <div>
                                    <p>
                                        Our staff creates tailored campaigns that support your objectives while putting a strong emphasis on quantifiable outcomes.
                                        Regardless of the size of your company—small or large—we offer professional advice and innovative solutions to optimize
                                        your return on investment and keep you at the forefront of the digital market.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                </main>
            </Layout>
        </div>
    )
}
