import React from "react";
import MySwiper from "../MYSwiper";
import { Link } from "react-router-dom";

function Intro() {
  return (
    <div>
      <div className="mx-auto px-4 sm:px-6 lg:px-8 py-6 max-w-screen-xl">
        <div className="lg:py-10" >
          <div className="text-center">
            <h1
              className="font-bold text-3xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl"
              style={{ color: "var(--primary-color)" }}
            >
              PrishaSoftware is a leading
              <br />
              Product
              <br />
              Based Software
              <span style={{ color: "var(--text-color)" }}> Company</span>
            </h1>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
            <div className="flex justify-center items-center sm:justify-end">
              <button
                className="mx-8 my-4 text-white py-2 px-6 rounded-full font-semibold"
                style={{
                  backgroundColor: "var(--text-color)",
                  boxShadow: "0px 6px 13px rgba(0, 0, 0, 0.5)",
                }}
              >
                <Link to="/about">Read More</Link>
              </button>
            </div>
            <div className="flex justify-center items-center sm:justify-start">
              <button
                className="mx-2 my-4 text-white py-2 px-6 rounded-full"
                style={{
                  backgroundColor: "var(--primary-color)",
                  boxShadow: "2px 6px 6px rgba(0, 0, 0, 0.5)",
                }}
              >
                <Link to="/contact">Get in Touch</Link>
              </button>
            </div>
          </div>
        </div>
        <section className="mx-auto  lg:mt-[5%] px-4 sm:px-4 s md:px-6 lg:px-8  max-w-screen-xl">
          <div
            className="flex justify-center items-center"
            data-aos-delay="00"
            data-aos="zoom-in"
          >
            <MySwiper />
          </div>
        </section>
      </div>
    </div>
  );
}

export default Intro;
