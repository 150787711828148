import React from "react";
import MetaTags from 'react-meta-tags';
import Layout from "../Components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faClock,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

function ContactUs() {


  return (
    <div>
      <div>
        <MetaTags>
          {/* Standard meta tags */}
          <title>
            Contact Prisha Software | Get in Touch for Custom IT Solutions
          </title>
          <meta
            name="description"
            content="Have questions or need assistance? Contact Prisha Software today! Our team is here to discuss your custom software development and IT solution needs."
          />
          <meta
            name="keywords"
            content="IT companies, IT companies near me, IT company in India"
          />

          {/* Facebook and LinkedIn meta tags */}
          <meta
            property="og:title"
            content="Contact Prisha Software | Get in Touch for Custom IT Solutions"
          />

          <meta
            property="og:image"
            content="https://www.prishasoftware.com/static/media/webimg.2e9a86f2fa5b53bab632.jpg"
          />
          <meta
            property="og:description"
            content="Have questions or need assistance? Contact Prisha Software today! Our team is here to discuss your custom software development and IT solution needs."
          />


          {/* LinkedIn meta tags */}
          <meta
            property="og:title"
            content="Contact Prisha Software | Get in Touch for Custom IT Solutions"
          />
          <meta
            property="og:image"
            content="https://www.prishasoftware.com/static/media/webimg.2e9a86f2fa5b53bab632.jpg"
          />
          <meta
            property="og:description"
            content="Have questions or need assistance? Contact Prisha Software today! Our team is here to discuss your custom software development and IT solution needs."
          />

          {/* Twitter meta tags */}
          <meta
            name="twitter:title"
            content="Contact Prisha Software | Get in Touch for Custom IT Solutions"
          />
          <meta
            property="twitter:image"
            content="https://www.prishasoftware.com/static/media/webimg.2e9a86f2fa5b53bab632.jpg"
          />
          <meta
            name="twitter:description"
            content="Have questions or need assistance? Contact Prisha Software today! Our team is here to discuss your custom software development and IT solution needs."
          />
        </MetaTags>
      </div>
      <Layout>
        <main>
          <div>
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 my-16">
              <div className="flex flex-wrap">
                <div className="w-full md:w-1/2 p-4 md:p-8">
                  <h2
                    className="text-3xl md:text-5xl font-bold mb-4"
                    style={{ color: "var(--primary-color)" }}
                  >
                    Get in{" "}
                    <span style={{ color: "var(--text-color)" }}>touch</span>
                  </h2>
                  <p className="text-gray-400 mb-6 md:mb-8">
                    If you have a query, please get in touch with us. We will
                    respond promptly.
                  </p>
                  <ul className="mb-6 md:mb-8">
                    <li className="text-gray-500 mb-2">
                      <span className="text-red-500 mr-2">
                        <i>
                          <FontAwesomeIcon icon={faMapMarkerAlt} />
                        </i>
                      </span>
                      1809, Wings Bussiness Bay, Umiya Chowk, 150ft Ring Road, Mavdi,
                      Rajkot, Gujarat 360004
                    </li>
                    <li className="text-gray-500 mb-2">
                      <span className="text-red-500 mr-2">
                        <i>
                          <FontAwesomeIcon icon={faClock} />
                        </i>
                      </span>
                      Monday - Saturday : 9am - 7pm
                    </li>
                    <li className="text-gray-500 mb-2">
                      <span className="text-red-500 mr-2">
                        <i>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </i>
                      </span>
                      info@Prishasoftware.com
                    </li>
                    <li className="text-gray-500">
                      <span className="text-red-500 mr-2">
                        <i>
                          <FontAwesomeIcon icon={faPhone} />
                        </i>
                      </span>
                      63536 51151
                    </li>
                  </ul>
                  {/* Rest of the contact form */}
                </div>

                <div className="w-full md:w-1/2 p-4 md:p-8">
                  <div className="h-11/12 md:h-full sm:h-6/12">
                    <iframe
                      title="CompanyAdress"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4908.940578635822!2d70.78559421180846!3d22.258155844335256!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959cb051b864ceb%3A0x24767fbaabd6987e!2sWings%20Business%20Bay!5e1!3m2!1sen!2sin!4v1727685921299!5m2!1sen!2sin"
                      className="w-full h-full"
                      style={{ border: 0 }}
                      loading="lazy"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </div>
  );
}

export default ContactUs;
